import { InlineIcon } from "@iconify/react";
import focusAddIcon from "@iconify-icons/uil/focus-add";
import { observer } from "mobx-react";
import { useAuthService } from "../../../auth/auth.service";
import { useBoolToggle } from "../../../hooks/useModal";
import { Button } from "../../../ui-kit/button/button.styles";
import { CreateButtonWrapper } from "../../../ui-kit/styles";
import { UserPermissions } from "../../../user/user.types";
import { CreateTelegramAccountModal } from "../create-telegram-account-modal/create-telegram-account-modal.component";

export const CreateTelegramAccount = observer(() => {
  const authService = useAuthService();

  const [isOpened, openModal, closeModal] = useBoolToggle();

  return (
    <>
      {authService.permissions.includes(
        UserPermissions.CREATE_TELEGRAM_ACCOUNT
      ) && (
        <>
          <CreateButtonWrapper>
            <Button onClick={openModal}>
              <InlineIcon icon={focusAddIcon} width="28" /> Add telegram account
            </Button>
          </CreateButtonWrapper>
          <CreateTelegramAccountModal
            closeModal={closeModal}
            isOpened={isOpened}
          />
        </>
      )}
    </>
  );
});
