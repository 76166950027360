import React from "react";
import { Control, useFieldArray, UseFormRegister } from "react-hook-form";
import { UpdateUserFormValues } from "../../user.types";
import { FormWrapper } from "../../../ui-kit/styles";

type UpdateUserFormProps = {
  updateUserControl: Control<UpdateUserFormValues>;
  updateUserRegister: UseFormRegister<UpdateUserFormValues>;
};

export const UpdateUserForm: React.FC<UpdateUserFormProps> = ({
  updateUserControl,
  updateUserRegister,
}) => {
  const { fields: updateUserFields } = useFieldArray({
    control: updateUserControl,
    name: "permissions",
  });

  return (
    <FormWrapper>
      {updateUserFields.map((field, index) => (
        <div key={`permissions-${field.label}`}>
          <input
            type={"checkbox"}
            {...updateUserRegister(`permissions.${index}.value` as const)}
            defaultChecked={field.value}
          />
          {field.label}
        </div>
      ))}
    </FormWrapper>
  );
};
