import { observer } from "mobx-react";
import { useEffect } from "react";
import { InformerStyled } from "../../../ui-kit/informer-wrapper/informer-wrapper.styles";
import { Typography } from "../../../ui-kit/typography/typography.component";
import { useBotService } from "../../bot.service";

export const LatestBotsWidget = observer(() => {
  const botService = useBotService();
  useEffect(() => {
    botService.fetchLatestBots();
  }, []);

  if (!botService.latestBots.airdrop) return null;

  return (
    <div>
      <InformerStyled size="l">
        <div>
          <Typography fontWeight="bold">Airdrop: </Typography>
          <Typography>{botService.latestBots.airdrop}</Typography>
        </div>
        <div>
          <Typography fontWeight="bold">Detective: </Typography>
          <Typography>{botService.latestBots.detective}</Typography>
        </div>
        <div>
          <Typography fontWeight="bold">ICO: </Typography>
          <Typography>{botService.latestBots.ico}</Typography>
        </div>
      </InformerStyled>
    </div>
  );
});
