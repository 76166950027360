import { action, makeObservable, observable } from "mobx";
import { toast } from "react-toastify";
import { injector } from "../Injector";
import { AuthRepo, HTTP_AUTH_REPO } from "./auth.repo";
import { UserPermissions } from "../user/user.types";
import { AxiosError } from "axios";

export const AUTH_SERVICE = "AUTH_SERVICE";

export interface AuthService {
  authToken: string | null;
  permissions: UserPermissions[];
  userId: string | null;

  login(email: string, password: string): Promise<void>;
  getMe(): Promise<void>;
  logout(): void;
}

export class AuthServiceImpl implements AuthService {
  private authRepo: AuthRepo = injector.get(HTTP_AUTH_REPO);

  constructor() {
    makeObservable(this);

    const token = localStorage.getItem("jwt");

    (async () => {
      if (token) {
        await this.getMe();
        this.authToken = token;
        return;
      }

      this.authToken = "";
    })();
  }

  @observable authToken: string | null = null;
  @observable permissions: UserPermissions[] = [];
  @observable userId: string | null = null;

  @action
  async login(email: string, password: string): Promise<void> {
    try {
      const token = await this.authRepo.login({ email, password });
      localStorage.setItem("jwt", token);
      await this.getMe();
      this.authToken = token;
    } catch (err) {
      const error = err as AxiosError;
      toast.error(error.response?.data?.message || error.message);
    }
  }

  @action
  async getMe(): Promise<void> {
    try {
      const response = await this.authRepo.me();
      this.permissions = response.permissions;
      this.userId = response.id;
    } catch (err) {
      const error = err as AxiosError;
      toast.error(error.response?.data?.message || error.message);
    }
  }

  @action
  logout() {
    this.permissions = [];
    this.userId = null;
    this.authToken = "";
    localStorage.removeItem("jwt");
  }
}

export const useAuthService = (): AuthService => {
  return injector.get<AuthService>(AUTH_SERVICE);
};
