import React, { ComponentProps, forwardRef } from "react";
import { UseFormRegister } from "react-hook-form";
import { DefaultTheme, StyledComponent } from "styled-components";
import { Typography } from "../typography/typography.component";
import { InputStyled, InputStyledProps, InputWrapper } from "./input.styles";

type InputProps = {
  label?: string;
  placeholder?: string;
  type?: React.ComponentProps<"input">["type"];
  name?: React.ComponentProps<"input">["name"];
  register?: UseFormRegister<any>;
  error?: string;
  as?: React.ComponentProps<typeof InputStyled>["as"];
  defaultValue?: string;
  wrapperClassName?: string;
  value?: string;
  onChange?: React.ComponentProps<"input">["onChange"];
};

export const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      label,
      placeholder,
      type,
      name,
      register,
      error,
      as,
      defaultValue,
      wrapperClassName,
      value,
      onChange,
    },
    ref
  ) => {
    let inputProps: any = {
      placeholder,
      type,
      as,
      additionalPadding: Boolean(as),
      defaultValue,
      value,
      onChange,
    };

    if (register) {
      inputProps = {
        ...inputProps,
        ...register(`${name}` as const),
      };
    } else {
      inputProps = {
        ...inputProps,
        ref,
      };
    }

    return (
      <InputWrapper className={wrapperClassName}>
        {label && (
          <Typography variant="h4" as="label" display="block">
            {label}
          </Typography>
        )}
        <InputStyled {...inputProps} />
        <Typography
          variant="h4"
          resetMargin
          className="input-error"
          fontSize={14}
        >
          {error}
        </Typography>
      </InputWrapper>
    );
  }
);
