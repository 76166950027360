import { opacify } from "polished";
import { DefaultTheme } from "styled-components";

export const lightTheme: DefaultTheme = {
  colors: {
    primaryPurple: "#6A4BFF",
    secondaryRed: "#FF7256",
    textBlack: "#25546F",
    textLineDark: "#EAEAEA",
    textGrey: "#92929D",
    primary: "#fff",
    inputBackground: "#fff",
    buttonTextColor: "#fff",
    widgetBorderColor: "#EAEAEA",
    widgetIconInformerBackground: opacify(-0.95, "#6A4BFF"),
    moreIconColor: "#000",
    allWhite: "#fff",
    green: "#3BC963",
    red: "#F95050",
    linkColor: "#25546F",
  },
  fonts: {
    primary: `Poppins, sans-serif`,
  },
};

export const darkTheme: DefaultTheme = {
  ...lightTheme,
  colors: {
    ...lightTheme.colors,
    primary: "#171725",
    textBlack: "#fff",
    inputBackground: "#1D2335",
    textLineDark: "#171725",
    widgetBorderColor: "#364670",
    widgetIconInformerBackground: "#2A334D",
    moreIconColor: "#fff",
    linkColor: "#fff",
  },
};
