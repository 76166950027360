import React, { useMemo } from "react";
import { CellProps } from "react-table";
import { observer } from "mobx-react";
import { DateTime } from "luxon";
import { Table } from "../../../ui-kit/table/table.component";
import { useBotService } from "../../bot.service";
import { Bot } from "../../bot.types";
import { TableStatusColumn } from "../table-status-column/table-status-column.component";
import { TableActionColumn } from "../table-action-column/table-action-column.component";
import { TableTurnedOnOffColumn } from "../table-turned-on-off-column/table-turned-on-off-column.component";
import { useAuthService } from "../../../auth/auth.service";
import { UserPermissions } from "../../../user/user.types";
import { useFetchBots } from "../../hooks/useFetchBots";
import { TableIconNameColumn } from "../table-bot-icon-column/table-bot-icon-column.component";
import { TableCreatorColumn } from "../table-creator-column/table-creator-column.component";

type BotListTableProps = {
  openClosingMessageModal: VoidFunction;
  openBotTokenModal: VoidFunction;
};

export const BotsListTable: React.FC<BotListTableProps> = observer(
  ({ openClosingMessageModal, openBotTokenModal }) => {
    const botService = useBotService();
    const authService = useAuthService();

    useFetchBots();

    const columns = useMemo(() => {
      const cols: any[] = [
        {
          Header: " ",
          Cell: TableIconNameColumn,
        },
        {
          Header: "Bot Name",
          accessor: "botName" as const,
        },
        {
          Header: "Status",
          Cell: TableStatusColumn,
        },
      ];

      if (
        authService.permissions.includes(UserPermissions.VIEW_TELEGRAM_ACCOUNTS)
      ) {
        cols.push({
          Header: "Owner",
          accessor: "ownerName" as const,
        });
      }

      if (
        authService.permissions.includes(UserPermissions.VIEW_TELEGRAM_ACCOUNTS)
      ) {
        cols.push({
          Header: "Creator",
          Cell: TableCreatorColumn,
        });
      }

      if (authService.permissions.includes(UserPermissions.VIEW_SERVERS)) {
        cols.push({
          Header: "Host IP",
          Cell: (cell: CellProps<Bot>) => (
            <a href={`ssh://root@${cell.row.original.hostIp}`}>
              {cell.row.original.hostIp}
            </a>
          ),
        });

        cols.push({
          Header: "Last time updated",
          Cell: (cell: CellProps<Bot>) => (
            <span>
              {DateTime.fromJSDate(cell.row.original.updatedAt).toLocaleString(
                DateTime.DATE_MED
              )}
            </span>
          ),
        });
      }

      cols.push(
        {
          Header: "Turned on/off",
          Cell: TableTurnedOnOffColumn,
        },
        {
          Header: "Action",
          Cell: (cell: CellProps<Bot>) => (
            <TableActionColumn
              openClosingMessageModal={openClosingMessageModal}
              openBotConfigModal={openBotTokenModal}
              {...cell}
            />
          ),
        }
      );

      return cols;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
      <Table columns={columns} data={botService.bots || []} tableKey="bots" />
    );
  }
);
