import { api } from "../config/api";
import { UsersListResponse } from "./dto/users-list-response.dto";
import { User } from "./user.types";
import { CreateUserRequestDTO } from "./dto/create-user-request-dto";
import { UpdateUserRequestDTO } from "./dto/update-user-request.dto";

export interface UserRepo {
  get(): Promise<User[]>;
  register(body: CreateUserRequestDTO): Promise<void>;
  delete(id: string): Promise<void>;
  update(id: string, dto: UpdateUserRequestDTO): Promise<void>;
}

export const HTTP_USER_REPO = "USER_HTTP_REPO";

export class HttpUserRepoImpl implements UserRepo {
  async get() {
    const { data } = await api.get<UsersListResponse>("/api/user");

    return data.map((user) => ({
      id: user._id,
      email: user.email,
      permissions: user.permissions,
    }));
  }

  async register(body: CreateUserRequestDTO) {
    await api.post(`/api/user`, body);
  }

  async delete(id: string) {
    await api.delete(`/api/user/${id}`);
  }

  async update(id: string, dto: UpdateUserRequestDTO) {
    await api.put(`/api/user/${id}`, dto);
  }
}
