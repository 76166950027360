import { opacify } from "polished";
import styled from "styled-components";

type ButtonProps = {
  fullWidth?: boolean;
  variant?: "primary" | "transparent";
  size?: "lg" | "sm";
  justifyContent?: "flex-start" | "center";
  noIconMargin?: boolean;
};

export const Button = styled.button<ButtonProps>`
  height: ${(p) => (p.size === "lg" ? 60 : 40)}px;
  background: ${(p) =>
    p.variant === "primary" ? p.theme.colors.primaryPurple : "transparent"};
  padding: 0 ${(p) => (p.size === "lg" ? 20 : 12)}px;
  border: 0;
  border-radius: 14px;
  color: ${(p) =>
    p.variant === "primary"
      ? p.theme.colors.buttonTextColor
      : p.theme.colors.textBlack};
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: all 0.3s ease;
  justify-content: ${(p) => p.justifyContent};

  ${(p) => p.fullWidth && "width: 100%;"}

  ${(p) =>
    p.variant === "transparent"
      ? `
    &:hover {
      background: ${p.theme.colors.primaryPurple};
      color: ${p.theme.colors.allWhite};
    }
  `
      : `
    &:hover {
      background: ${opacify(-0.1, p.theme.colors.primaryPurple)}
    }
  `}

  svg {
    ${(p) => !p.noIconMargin && `margin-right: ${p.size === "lg" ? 24 : 12}px;`}
  }

  &[disabled] {
    background-color: ${(p) => p.theme.colors.textLineDark};
    color: ${(p) => p.theme.colors.textGrey};
  }
`;

Button.defaultProps = {
  variant: "primary",
  size: "lg",
  justifyContent: "flex-start",
  noIconMargin: false,
};
