import { api } from "../config/api";
import { CreateTelegramAccountDto } from "./dto/create-telegram-account.dto";
import { TelegramAccountsListDto } from "./dto/telegram-accounts-list.dto";
import { TelegramAccountsOrderDto } from "./dto/telegram-accounts-order.dto";
import { TelegramAccount } from "./telegram-account.types";

export interface TelegramAccountRepo {
  get(): Promise<TelegramAccount[]>;
  create(dto: CreateTelegramAccountDto): Promise<void>;
  delete(id: string): Promise<void>;
  updateOrder(dto: TelegramAccountsOrderDto): Promise<void>;
}

export const HTTP_TELEGRAM_ACCOUNT_REPO = "HTTP_TELEGRAM_ACCOUNT_REPO";

export class HttpTelegramAccountRepoImpl implements TelegramAccountRepo {
  async get() {
    const { data } = await api.get<TelegramAccountsListDto>(
      "/api/telegram-accounts"
    );

    return data.map((account) => ({
      id: account._id,
      name: account.name,
      phoneNumber: account.phoneNumber,
    }));
  }

  async create(dto: CreateTelegramAccountDto): Promise<void> {
    await api.post("/api/telegram-accounts", dto);
  }

  async delete(id: string): Promise<void> {
    await api.delete(`/api/telegram-accounts/${id}`);
  }

  async updateOrder(dto: TelegramAccountsOrderDto): Promise<void> {
    await api.post("/api/telegram-accounts/order", dto);
  }
}
