import { InlineIcon } from "@iconify/react";
import React from "react";
import { CellProps } from "react-table";
import lockIcon from "@iconify-icons/uil/lock";
import unlockIcon from "@iconify-icons/uil/unlock";
import { Bot } from "../../bot.types";
import { useTheme } from "styled-components";

type TableStatusColumnProps = CellProps<Bot>;

export const TableStatusColumn: React.FC<TableStatusColumnProps> = (cell) => {
  const theme = useTheme();

  return (
    <InlineIcon
      icon={cell.row.original.isClosed ? lockIcon : unlockIcon}
      width={28}
      color={cell.row.original.isClosed ? theme.colors.red : theme.colors.green}
    />
  );
};
