import React, { useEffect } from "react";
import { Button } from "../../../ui-kit/button/button.styles";
import { Modal } from "../../../ui-kit/modal/modal.component";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useBotService } from "../../../bot/bot.service";
import { Server } from "../../../servers/server.types";
import { toast } from "react-toastify";
import { useServerService } from "../../../servers/server.service";
import { AxiosError } from "axios";
import { Input } from "../../../ui-kit/input/input.component";
import { useTelegramAccountService } from "../../../telegram-accounts/telegram-account.service";

type DeployBotModalProps = {
  isOpened: boolean;
  closeModal: () => void;
  serverHost: Server | null;
};

export type DeployBotFormValues = {
  botName: string;
  owner: string;
};

const deployBotSchemaValidation = yup.object().shape({
  botName: yup.string().required(),
  owner: yup.string().required(),
});

export const DeployBotModal: React.FC<DeployBotModalProps> = ({
  isOpened,
  closeModal,
  serverHost,
}) => {
  const botService = useBotService();
  const serverService = useServerService();
  const telegramAccountService = useTelegramAccountService();

  useEffect(() => {
    telegramAccountService.getAllAccounts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<DeployBotFormValues>({
    resolver: yupResolver(deployBotSchemaValidation),
    defaultValues: {
      botName: "",
      owner: "",
    },
  });

  const onSubmit = async (values: DeployBotFormValues) => {
    try {
      const closedBotName = await botService.deployBot(
        serverHost!.id,
        values.botName,
        values.owner
      );
      closeModal();
      toast.success(`${values.botName} has been deployed!`);
      if (closedBotName !== null && closedBotName !== "null") {
        toast.info(
          `${closedBotName} has been closed. Click here to copy name!`,
          {
            autoClose: false,
            onClick: async () => {
              await navigator.clipboard.writeText(closedBotName);
            },
          }
        );
      }
      serverService.getAllServers();
    } catch (e) {
      const error = e as AxiosError;

      toast.error(
        Array.isArray(error.response?.data?.message)
          ? error.response?.data?.message[0]
          : error.response?.data?.message || error.message
      );
    }
  };
  const botTokenModalFooter = (
    <Button size="sm" onClick={handleSubmit(onSubmit)} disabled={isSubmitting}>
      {isSubmitting ? "Deploying..." : "Deploy"}
    </Button>
  );

  return (
    <Modal
      isOpened={isOpened}
      closeModal={closeModal}
      title="Deploy bot to server"
      footer={botTokenModalFooter}
    >
      <Input
        label="Bot name"
        register={register}
        name="botName"
        error={errors["botName"]?.message}
      />
      <br />
      <br />
      <select {...register("owner")}>
        <option value="">Select telegram account</option>
        {telegramAccountService.accounts.map((acc) => (
          <option value={acc.id} key={`account-${acc.id}`}>
            {acc.name}
          </option>
        ))}
      </select>
    </Modal>
  );
};
