import React from "react";
import { UseFormReturn } from "react-hook-form";
import { Button } from "../../../ui-kit/button/button.styles";
import { Modal } from "../../../ui-kit/modal/modal.component";
import { useServerService } from "../../server.service";
import { toast } from "react-toastify";
import { AxiosError } from "axios";
import { FormWrapper } from "../../../ui-kit/styles";
import { Input } from "../../../ui-kit/input/input.component";
import { UpdateServerFormValues } from "../../server.types";

type UpdateServerModalProps = {
  isOpened: boolean;
  closeModal: () => void;
  form: Omit<UseFormReturn<any>, "reset">;
};

export const UpdateServerModal: React.FC<UpdateServerModalProps> = ({
  isOpened,
  closeModal,
  form,
}) => {
  const serverService = useServerService();
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = form;

  const onUpdate = async (values: UpdateServerFormValues) => {
    try {
      await serverService.updateServer(values);
      closeModal();
      toast.success(`${values.ip} server changed!`);
      serverService.deselectServer();
      serverService.getAllServers();
    } catch (e) {
      const error = e as AxiosError;
      toast.error(
        Array.isArray(error.response?.data?.message)
          ? error.response?.data?.message[0]
          : error.response?.data?.message || error.message
      );
    }
  };

  const modalFooter = (
    <Button size="sm" onClick={handleSubmit(onUpdate)}>
      Submit
    </Button>
  );

  return (
    <Modal
      isOpened={isOpened}
      closeModal={closeModal}
      title="Update server"
      footer={modalFooter}
    >
      <FormWrapper>
        <Input
          label="Server IP"
          register={register}
          name="ip"
          defaultValue={serverService.selectedServer?.ip}
          error={errors["ip"]?.message}
        />
        <Input
          label="MongoDB Host"
          register={register}
          name="mongoHost"
          defaultValue={serverService.selectedServer?.mongoHost}
          error={errors["mongoHost"]?.message}
        />
      </FormWrapper>
    </Modal>
  );
};
