import { api } from "../config/api";
import { Bot, BotResponse, LatestBots } from "./bot.types";
import { BotListFilter } from "./dto/bot-list-filter.dto";
import { BotListResponse } from "./dto/bot-list-response.dto";
import { BotUpdateRequest } from "./dto/bot-update-request.dto";
import { BotUpdateResponse } from "./dto/bot-update-response.dto";
import { DeployBotRequestDTO } from "./dto/deploy-bot-request.dto";
import { DeployBotResponseDto } from "./dto/deploy-bot-response.dto";
import { TurnOnOffResponseDto } from "./dto/turn-on-off-response.dto";

export interface BotRepo {
  get(filter: BotListFilter): Promise<[Bot[], number]>;
  update(id: string, dto: Partial<BotUpdateRequest>): Promise<Bot>;
  restartBot(id: string): Promise<void>;
  deployBot(dto: DeployBotRequestDTO): Promise<string>;
  turnOnOff(id: string): Promise<Bot>;
  downloadFile(id: string): Promise<Blob>;
  uploadFile(id: string, file: File): Promise<void>;
  downloadReport(): Promise<Blob>;
  latestBots(): Promise<LatestBots>;
}

export const HTTP_BOT_REPO = "HTTP_BOT_REPO";

export class HttpBotRepoImpl implements BotRepo {
  private prepareBotData = (data: BotResponse): Bot => ({
    id: data._id,
    botName: data.botName,
    closingMessage: data.closedMessage,
    isClosed: data.isClosed,
    botToken: data.botToken,
    groupName: data.groupName,
    channelName: data.channelName,
    token: data.token,
    perReferral: data.perReferral,
    referralLimit: data.referralLimit,
    decimals: data.decimals,
    mandatoryReward: data.mandatoryReward,
    perToken: data.perToken,
    turnedOn: data.turnedOn,
    ownerName: data.owner?.name ?? "-",
    hostIp: data.hostIp,
    advertiser: data.advertiser,
    creator: data.creator?.email ?? "-",
    updatedAt: new Date(data.updatedAt),
  });

  async get(filter: BotListFilter): Promise<[Bot[], number]> {
    const { data } = await api.get<BotListResponse>("/api/bot", {
      params: filter,
    });

    return [data.data.map(this.prepareBotData), data.meta.total];
  }

  async update(id: string, dto: Partial<BotUpdateRequest>): Promise<Bot> {
    const { data } = await api.patch<BotUpdateResponse>(`/api/bot/${id}`, dto);

    return this.prepareBotData(data);
  }

  async restartBot(id: string): Promise<void> {
    return api.patch(`/api/bot/${id}/restart`);
  }

  async deployBot(dto: DeployBotRequestDTO): Promise<string> {
    const { data } = await api.patch<DeployBotResponseDto>(
      `/api/bot/deploy`,
      dto
    );

    return data.closedBot;
  }

  async turnOnOff(id: string): Promise<Bot> {
    const { data } = await api.patch<TurnOnOffResponseDto>(
      `/api/bot/${id}/turn-on-off`
    );

    return this.prepareBotData(data);
  }

  async downloadFile(id: string): Promise<Blob> {
    const { data } = await api.get(`/api/bot/${id}/language-file`, {
      responseType: "blob",
    });

    return new Blob([data]);
  }

  async uploadFile(id: string, file: File): Promise<void> {
    const formData = new FormData();
    formData.append("language_file", file);

    await api.post(`/api/bot/${id}/language-file`, formData);
  }

  async downloadReport(): Promise<Blob> {
    const { data } = await api.get(`/api/bot/report`, {
      responseType: "arraybuffer",
    });

    return new Blob([data]);
  }

  async latestBots(): Promise<LatestBots> {
    const { data } = await api.get("/api/bot/latest-bots");

    return {
      detective: data.detectiveBot,
      ico: data.icoBot,
      airdrop: data.airdropstarBot,
    };
  }
}
