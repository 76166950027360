import axios from "axios";
import { apiUrl } from "./configuration";

export const api = axios.create({
  baseURL: apiUrl,
});

api.interceptors.request.use(
  (config) => {
    const jwt = localStorage.getItem("jwt");

    if (jwt) {
      config.headers = {
        ...config.headers,
        Authorization: `Bearer ${jwt}`,
      };
    }

    return config;
  },
  (err) => {
    Promise.reject(err);
  }
);
