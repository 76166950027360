import { opacify } from "polished";
import styled from "styled-components";
import { InputWrapper } from "../../../ui-kit/input/input.styles";

export const SignInWrapper = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  background: ${(p) => p.theme.colors.primary};
`;

export const SignInBackground = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: ${(p) => opacify(-0.97, p.theme.colors.primaryPurple)};
  overflow: hidden;

  &:before {
    position: absolute;
    width: 100vw;
    height: calc(100vh * 2);
    content: "";
    display: block;
    background: ${(p) => p.theme.colors.primary};
    left: calc(100vw * 0.45);
    border-radius: 50%;
    top: -50%;
  }
`;

export const SignInLeftSide = styled.div`
  flex: 1;
  padding-top: 56px;
  padding-left: 56px;
  z-index: 9;

  .people {
    margin-top: 73px;
  }
`;

export const SignInRightSide = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 1;

  ${InputWrapper} {
    margin-bottom: 32px;
  }

  .sign-in-title {
    margin-bottom: 48px;
  }
`;

export const SignInFormWrapper = styled.form`
  max-width: 484px;
  width: 100%;
`;
