import { InlineIcon } from "@iconify/react";
import React from "react";
import { CellProps } from "react-table";
import playIcon from "@iconify-icons/uil/play";
import pauseIcon from "@iconify-icons/uil/pause";
import { Bot } from "../../bot.types";
import { useTheme } from "styled-components";

type TableTurnedOnOffColumnProps = CellProps<Bot>;

export const TableTurnedOnOffColumn: React.FC<TableTurnedOnOffColumnProps> = (
  cell
) => {
  const theme = useTheme();

  return (
    <InlineIcon
      icon={cell.row.original.turnedOn ? playIcon : pauseIcon}
      width={28}
      color={cell.row.original.turnedOn ? theme.colors.green : theme.colors.red}
    />
  );
};
