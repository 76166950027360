import { Route } from "react-router";
import { ServersList } from "./components/servers-list/servers-list.component";

export const ServerEntrypoint = () => {
  return (
    <>
      <Route path="/servers" component={ServersList} />
    </>
  );
};
