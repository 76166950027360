import { Redirect, Route, Switch } from "react-router";
import { SignIn } from "./components/sign-in/sign-in.component";

export const AuthEntrypoint = () => {
  return (
    <Switch>
      <Route path="/auth/sign-in" component={SignIn} />
      <Redirect to="/auth/sign-in" />
    </Switch>
  );
};
