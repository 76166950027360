import { observer } from "mobx-react";
import React from "react";
import { CreateServerModal } from "../create-server-modal/create-server-modal.component";
import { ServersTable } from "../servers-table/servers-table.component";

export const ServersList = observer(() => {
  return (
    <div>
      <CreateServerModal />
      <ServersTable />
    </div>
  );
});
