import dashboardIcon from "@iconify-icons/uil/create-dashboard";
import { observer } from "mobx-react";
import { Logo } from "../logo/logo.component";
import {
  DashboardContentWrapper,
  DashboardWrapper,
  Sidebar,
  SidebarMenuWrapper,
} from "./dashboard.styles";
import { SidebarLink } from "../sidebar-link/sidebar-link.component";
import { useAuthService } from "../../auth/auth.service";
import { UserPermissions } from "../../user/user.types";
import usersAltIcon from "@iconify-icons/uil/users-alt";
import robotIcon from "@iconify-icons/uil/robot";
import serversIcon from "@iconify-icons/uil/servers";
import { Button } from "../button/button.styles";
import { InlineIcon } from "@iconify/react";
import signoutIcon from "@iconify-icons/uil/signout";
import { Typography } from "../typography/typography.component";
import { useHistory } from "react-router";
import { ThemeSwitcher } from "../theme-switcher/theme-switcher.component";
import invoiceIcon from "@iconify-icons/uil/invoice";
import telegramIcon from "@iconify-icons/uil/telegram-alt";

export const Dashboard: React.FC<{}> = observer(({ children }) => {
  const authService = useAuthService();
  const history = useHistory();

  const signout = () => {
    authService.logout();
    history.push("/");
  };

  return (
    <DashboardWrapper>
      <div>
        <Sidebar>
          <Logo wrapperClassName="sidebar-logo" />
          <SidebarMenuWrapper>
            <SidebarLink icon={dashboardIcon} path="/">
              Dashboard
            </SidebarLink>
            {authService.permissions.includes(UserPermissions.VIEW_BOTS) && (
              <SidebarLink icon={robotIcon} path="/bots">
                Bots
              </SidebarLink>
            )}
            {authService.permissions.includes(UserPermissions.VIEW_USERS) && (
              <SidebarLink icon={usersAltIcon} path="/users">
                Users
              </SidebarLink>
            )}
            {authService.permissions.includes(UserPermissions.VIEW_SERVERS) && (
              <SidebarLink icon={serversIcon} path="/servers">
                Servers
              </SidebarLink>
            )}
            {authService.permissions.includes(
              UserPermissions.VIEW_INVOICES
            ) && (
              <SidebarLink icon={invoiceIcon} path="/invoices">
                Invoices
              </SidebarLink>
            )}
            {authService.permissions.includes(
              UserPermissions.VIEW_TELEGRAM_ACCOUNTS
            ) && (
              <SidebarLink icon={telegramIcon} path="/telegram-accounts">
                Telegram Accounts
              </SidebarLink>
            )}
            <Button fullWidth variant="transparent" onClick={signout}>
              <InlineIcon icon={signoutIcon} width="28" />
              <Typography
                variant="h3"
                resetMargin
                fontColor="inherit"
                fontWeight={400}
              >
                Sign Out
              </Typography>
            </Button>
            <ThemeSwitcher />
          </SidebarMenuWrapper>
        </Sidebar>
      </div>
      <DashboardContentWrapper>{children}</DashboardContentWrapper>
    </DashboardWrapper>
  );
});
