import ReactPaginate from "react-paginate";
import { useBotService } from "../../bot.service";
import { PaginationWrapper } from "./bot-table-pagination.styles";

export const BotTablePagination = () => {
  const botService = useBotService();
  const onPageChange = ({ selected }: { selected: number }) => {
    botService.changePage(selected);
  };

  return (
    <PaginationWrapper>
      <ReactPaginate
        pageCount={botService.totalPages}
        onPageChange={onPageChange}
        pageRangeDisplayed={3}
        marginPagesDisplayed={1}
        containerClassName={"pagination-container"}
        pageClassName={"pagination-item"}
        previousClassName={"pagination-item"}
        nextClassName={"pagination-item"}
        disabledClassName={"pagination-item-disabled"}
        activeClassName={"pagination-item-active"}
      />
    </PaginationWrapper>
  );
};
