import styled from "styled-components";

export const ThemeSwitcherStyled = styled.div`
  input {
    display: none;
  }

  label {
    position: absolute;
    z-index: 2;
    padding: 1px;
    height: 28px;
    width: 50px;
    border-radius: 20px;
    background: ${(p) => p.theme.colors.primary};
    border: 3px solid ${(p) => p.theme.colors.widgetBorderColor};
    cursor: pointer;
    transition: all 0.3s ease;
  }

  .switcher {
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 20px;
    vertical-align: middle;
  }

  #on {
    position: absolute;
    z-index: 2;
    background: ${(p) => p.theme.colors.primaryPurple};
    border: 2px solid ${(p) => p.theme.colors.primaryPurple};
    transition: all 0.3s ease;
  }

  .toggle:checked ~ label #on {
    transform: translateX(100%);
  }
`;
