import React, { useContext } from "react";
import { themeContext } from "../theme-context/theme.context";
import { ThemeSwitcherStyled } from "./theme-switcher.styles";

export const ThemeSwitcher: React.FC<{}> = () => {
  const themeService = useContext(themeContext);

  return (
    <ThemeSwitcherStyled>
      <input
        type="checkbox"
        className="toggle"
        id="switch"
        onChange={themeService.onThemeToggl}
        checked={themeService.theme === "light"}
      />
      <label htmlFor="switch">
        <div className="switcher" id="on"></div>
      </label>
    </ThemeSwitcherStyled>
  );
};
