import { Route } from "react-router-dom";
import { UsersList } from "./components/users-list/users-list.component";

export const UserEntypoint = () => {
  return (
    <>
      <Route path="/users" component={UsersList} />
    </>
  );
};
