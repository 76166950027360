import { api } from "../config/api";
import { LoginRequestDTO } from "./dto/login-request.dto";
import { LoginResponseDTO } from "./dto/login-response.dto";
import { MeResponseDTO } from "./dto/me-response.dto";
import { UserMeData } from "./auth.types";

export interface AuthRepo {
  login(dto: LoginRequestDTO): Promise<string>;
  me(): Promise<UserMeData>;
}

export const HTTP_AUTH_REPO = "HTTP_AUTH_REPO";

export class HttpAuthRepoImpl implements AuthRepo {
  async login(dto: LoginRequestDTO): Promise<string> {
    const { data } = await api.post<LoginResponseDTO>("/api/auth/login", dto);

    return data.access_token;
  }

  async me(): Promise<UserMeData> {
    const { data } = await api.get<MeResponseDTO>("/api/auth/me");
    return {
      id: data._id,
      permissions: data.permissions,
    };
  }
}
