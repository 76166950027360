import { useBoolToggle } from "../../../hooks/useModal";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import { Button } from "../../../ui-kit/button/button.styles";
import React from "react";
import { UserPermissions } from "../../user.types";
import { Modal } from "../../../ui-kit/modal/modal.component";
import { Input } from "../../../ui-kit/input/input.component";
import { useAuthService } from "../../../auth/auth.service";
import * as yup from "yup";
import { useUserService } from "../../user.service";
import { CreateUserBtn } from "../create-user-btn/create-user-btn.component";
import { FormWrapper } from "../../../ui-kit/styles";
import { AxiosError } from "axios";

type CreateUserFormValues = {
  email: string;
  password: string;
};

const createUserSchemaValidation = yup.object().shape({
  email: yup.string().email().required(),
  password: yup.string().min(6).required(),
});

export const CreateUserModal = () => {
  const authService = useAuthService();
  const userService = useUserService();

  const [isCreatedUserOpened, openCreateUserModal, closeCreateUserModel] =
    useBoolToggle();
  const {
    register: createUserRegister,
    handleSubmit: createUserHandleSubmit,
    formState: { errors: createUserErrors },
  } = useForm<CreateUserFormValues>({
    resolver: yupResolver(createUserSchemaValidation),
  });
  const onCreateUserSubmit = async (values: CreateUserFormValues) => {
    try {
      await userService.register(values);
      toast.success(`${values.email} is created!`);
      closeCreateUserModel();
    } catch (e) {
      const error = e as AxiosError;
      toast.error(error.response?.data?.message || error.message);
    }
  };
  const createUserModalFooter = (
    <Button size="sm" onClick={createUserHandleSubmit(onCreateUserSubmit)}>
      Create user
    </Button>
  );

  return authService.permissions.includes(UserPermissions.CREATE_USER) ? (
    <>
      <Modal
        isOpened={isCreatedUserOpened}
        closeModal={closeCreateUserModel}
        title={"Create user"}
        footer={createUserModalFooter}
      >
        <FormWrapper>
          <Input
            label="Email"
            register={createUserRegister}
            name="email"
            error={createUserErrors["email"]?.message}
          />
          <Input
            label="Password"
            register={createUserRegister}
            name="password"
            error={createUserErrors["password"]?.message}
            type="password"
          />
        </FormWrapper>
      </Modal>
      <CreateUserBtn openCreateUserModal={openCreateUserModal} />
    </>
  ) : null;
};
