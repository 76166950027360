import { Route } from "react-router";
import { InvoicesList } from "./components/invoices-list/invoices-list.component";

export const InvoicesEntrypoint = () => {
  return (
    <>
      <Route path="/invoices" component={InvoicesList} />
    </>
  );
};
