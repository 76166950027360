import { DndProvider } from "react-dnd";
import { useTable } from "react-table";
import { HTML5Backend } from "react-dnd-html5-backend";
import {
  TableHead,
  TableHeadCell,
  TableRow,
  TableStyled,
} from "./table.styles";
import { TableRowDrag } from "../table-row-drag/table-row-drag.component";
import { TableRowDragLayer } from "../table-row-drag-layer/table-row-drag-layer.component";

type TableProps = {
  columns: Array<any>;
  data: Array<any>;
  tableKey: string;
  dropCallback?: (dragId: string, dropId: string) => void;
};

export const Table: React.FC<TableProps> = ({
  columns,
  data,
  tableKey,
  dropCallback,
}) => {
  const { getTableProps, headerGroups, getTableBodyProps, rows, prepareRow } =
    useTable({ columns, data });

  return (
    <DndProvider backend={HTML5Backend}>
      {dropCallback ? <TableRowDragLayer /> : null}
      <TableStyled {...getTableProps()} cellSpacing={0} cellPadding={0}>
        <TableHead>
          {headerGroups.map((headerGroup) => (
            <TableRow {...headerGroup.getHeaderGroupProps()} isHeader>
              {dropCallback ? <TableHeadCell>Move</TableHeadCell> : null}
              {headerGroup.headers.map((column) => (
                <TableHeadCell {...column.getHeaderProps()}>
                  {column.render("Header")}
                </TableHeadCell>
              ))}
            </TableRow>
          ))}
        </TableHead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);

            return (
              <TableRowDrag
                row={row}
                dropCallback={dropCallback}
                key={`${tableKey}-${row.original.id}`}
              />
            );
          })}
        </tbody>
      </TableStyled>
    </DndProvider>
  );
};
