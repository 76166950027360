import { nanoid } from "nanoid";
import { useMemo } from "react";

export const useMemoId = () => {
  const id = useMemo(() => {
    return nanoid();
  }, []);

  return id;
};
