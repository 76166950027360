import { yupResolver } from "@hookform/resolvers/yup";
import React from "react";
import { useForm } from "react-hook-form";
import { Button } from "../../../ui-kit/button/button.styles";
import { Modal } from "../../../ui-kit/modal/modal.component";
import { useBotService } from "../../bot.service";
import * as yup from "yup";
import { toast } from "react-toastify";
import { AxiosError } from "axios";
import { Input } from "../../../ui-kit/input/input.component";
import { FormWrapper } from "../../../ui-kit/styles";
import { BotConfigFormValues } from "../../bot.types";

type ChangeBotConfigModalProps = {
  isOpened: boolean;
  closeModal: VoidFunction;
};

const botConfigSchemaValidation = yup.object().shape({
  botToken: yup.string().required(),
  botName: yup.string().required(),
  groupName: yup.string().required(),
  channelName: yup.string().required(),
  token: yup.string().required(),
  perReferral: yup.number().required(),
  referralLimit: yup.number().required(),
  decimals: yup.number().required(),
  mandatoryReward: yup.number().required(),
  perToken: yup.number().required(),
  advertiser: yup.string().required(),
});

export const ChangeBotConfigModal: React.FC<ChangeBotConfigModalProps> = ({
  isOpened,
  closeModal,
}) => {
  const botService = useBotService();

  const {
    register: botTokenFormRegister,
    handleSubmit: botTokenHandleSubmit,
    formState: { errors: botTokenErrors },
    reset,
  } = useForm<BotConfigFormValues>({
    resolver: yupResolver(botConfigSchemaValidation),
  });

  const onBotTokenSubmit = async (values: BotConfigFormValues) => {
    // eslint-disable-next-line no-restricted-globals
    const validateSubmit = confirm(
      `Are you sure that you want to change config for ${botService.selectedBot?.botName}?`
    );

    if (!validateSubmit) return;

    try {
      await botService.changeConfig(values);
      closeModal();
      toast.success(`${botService.selectedBot?.botName} config changed!`);
      botService.deselectBot();
      reset();
      botService.fetchBots();
    } catch (e) {
      const error = e as AxiosError;
      toast.error(
        Array.isArray(error.response?.data?.message)
          ? error.response?.data?.message[0]
          : error.response?.data?.message || error.message
      );
    }
  };

  const botTokenModalFooter = (
    <Button size="sm" onClick={botTokenHandleSubmit(onBotTokenSubmit)}>
      Submit
    </Button>
  );

  const onModalClose = () => {
    reset();
    closeModal();
  };

  return (
    <Modal
      isOpened={isOpened}
      closeModal={onModalClose}
      title="Change bot config"
      footer={botTokenModalFooter}
    >
      <FormWrapper>
        <Input
          label="Bot token"
          register={botTokenFormRegister}
          name="botToken"
          defaultValue={botService.selectedBot?.botToken}
          error={botTokenErrors["botToken"]?.message}
        />
        <Input
          label="Bot name"
          register={botTokenFormRegister}
          name="botName"
          defaultValue={botService.selectedBot?.botName}
          error={botTokenErrors["botName"]?.message}
        />
        <Input
          label="Group name"
          register={botTokenFormRegister}
          name="groupName"
          defaultValue={botService.selectedBot?.groupName}
          error={botTokenErrors["groupName"]?.message}
        />
        <Input
          label="Channel name"
          register={botTokenFormRegister}
          name="channelName"
          defaultValue={botService.selectedBot?.channelName}
          error={botTokenErrors["channelName"]?.message}
        />
        <Input
          label="Token name"
          register={botTokenFormRegister}
          name="token"
          defaultValue={botService.selectedBot?.token}
          error={botTokenErrors["token"]?.message}
        />
        <Input
          label="Per referral"
          register={botTokenFormRegister}
          name="perReferral"
          defaultValue={botService.selectedBot?.perReferral.toString()}
          error={botTokenErrors["perReferral"]?.message}
          type="number"
        />
        <Input
          label="Referral limit"
          register={botTokenFormRegister}
          name="referralLimit"
          defaultValue={botService.selectedBot?.referralLimit.toString()}
          error={botTokenErrors["referralLimit"]?.message}
          type="number"
        />
        <Input
          label="Decimals"
          register={botTokenFormRegister}
          name="decimals"
          defaultValue={botService.selectedBot?.decimals.toString()}
          error={botTokenErrors["decimals"]?.message}
          type="number"
        />
        <Input
          label="Mandatory reward"
          register={botTokenFormRegister}
          name="mandatoryReward"
          defaultValue={botService.selectedBot?.mandatoryReward.toString()}
          error={botTokenErrors["mandatoryReward"]?.message}
          type="number"
        />
        <Input
          label="Per token"
          register={botTokenFormRegister}
          name="perToken"
          defaultValue={botService.selectedBot?.perToken.toString()}
          error={botTokenErrors["perToken"]?.message}
          type="number"
        />
        <Input
          label="Advertiser"
          register={botTokenFormRegister}
          name="advertiser"
          defaultValue={botService.selectedBot?.advertiser.toString()}
          error={botTokenErrors["advertiser"]?.message}
          type="text"
        />
      </FormWrapper>
    </Modal>
  );
};
