import { FC, useEffect } from "react";
import { useDrag, useDrop } from "react-dnd";
import { getEmptyImage } from "react-dnd-html5-backend";
import { Row } from "react-table";
import draggabledotsIcon from "@iconify-icons/uil/draggabledots";
import { TableCell, TableRow } from "../table/table.styles";
import Icon from "@iconify/react";

type TableRowDragProps = {
  row: Row<any>;
  dropCallback?: (dragId: string, dropId: string) => void;
};

const TABLE_ROW_ITEM = "TABLE_ROW_ITEM";

export const TableRowDrag: FC<TableRowDragProps> = ({ row, dropCallback }) => {
  const rowItem = row.original;

  const [{ opacity, isDragging }, dragRef, dragPreview] = useDrag(
    () => ({
      type: TABLE_ROW_ITEM,
      item: { id: rowItem.id, row },
      collect: (monitor) => ({
        opacity: monitor.isDragging() ? 0.5 : 1,
        isDragging: monitor.isDragging(),
      }),
    }),
    []
  );
  useEffect(() => {
    dragPreview(getEmptyImage(), { captureDraggingState: true });
  }, [dragPreview]);

  const [, dropRef] = useDrop(() => ({
    accept: TABLE_ROW_ITEM,
    drop: (dragItem: any) => {
      if (dragItem.id === rowItem.id) {
        return;
      }

      if (dropCallback) {
        dropCallback(dragItem.id, rowItem.id);
      }
    },
  }));

  return (
    <TableRow {...row.getRowProps()} style={{ opacity }} ref={dropRef}>
      {dropCallback ? (
        <TableCell isDrag isDragging={isDragging}>
          <span ref={dragRef}>
            <Icon icon={draggabledotsIcon} width="28" />
          </span>
        </TableCell>
      ) : null}
      {row.cells.map((cell) => (
        <TableCell {...cell.getCellProps()}>{cell.render("Cell")}</TableCell>
      ))}
    </TableRow>
  );
};
