import { action, makeObservable, observable } from "mobx";
import { injector } from "../Injector";
import { CreateTelegramAccountDto } from "./dto/create-telegram-account.dto";
import {
  HTTP_TELEGRAM_ACCOUNT_REPO,
  TelegramAccountRepo,
} from "./telegram-account.repo";
import { TelegramAccount } from "./telegram-account.types";

export interface TelegramAccountService {
  accounts: TelegramAccount[];

  getAllAccounts(): Promise<void>;
  createAccount(values: CreateTelegramAccountDto): Promise<void>;
  delete(id: string): Promise<void>;
  reorderAccounts(dragId: string, dropId: string): void;
}

export const TELEGRAM_ACCOUNT_SERVICE = "TELEGRAM_ACCOUNT_SERVICE";

export class TelegramAccountServiceImpl implements TelegramAccountService {
  private telegramAccountRepo: TelegramAccountRepo = injector.get(
    HTTP_TELEGRAM_ACCOUNT_REPO
  );

  constructor() {
    makeObservable(this);
  }

  @observable accounts: TelegramAccount[] = [];

  @action
  async getAllAccounts(): Promise<void> {
    this.accounts = await this.telegramAccountRepo.get();
  }

  @action
  async createAccount(values: CreateTelegramAccountDto): Promise<void> {
    await this.telegramAccountRepo.create(values);
    await this.getAllAccounts();
  }

  @action
  async delete(id: string): Promise<void> {
    await this.telegramAccountRepo.delete(id);
  }

  @action.bound
  async reorderAccounts(dragId: string, dropId: string) {
    const dragItemIndex = this.accounts.findIndex((d) => d.id === dragId);
    const dropItemIndex = this.accounts.findIndex((d) => d.id === dropId);

    this.accounts[dragItemIndex] = this.accounts.splice(
      dropItemIndex,
      1,
      this.accounts[dragItemIndex]
    )[0];

    await this.telegramAccountRepo.updateOrder({
      order: this.accounts.map((o, i) => ({ id: o.id, order: i + 1 })),
    });
  }
}

export const useTelegramAccountService = (): TelegramAccountService => {
  return injector.get<TelegramAccountService>(TELEGRAM_ACCOUNT_SERVICE);
};
