import { observer } from "mobx-react";
import React, { useEffect, useMemo } from "react";
import { toast } from "react-toastify";
import { useServerService } from "../../server.service";
import { Table } from "../../../ui-kit/table/table.component";
import { CellProps } from "react-table";
import { Server, UpdateServerFormValues } from "../../server.types";
import { AxiosError } from "axios";
import { useAuthService } from "../../../auth/auth.service";
import { UserPermissions } from "../../../user/user.types";
import rotate360 from "@iconify-icons/uil/rotate-360";
import { DropdownMenu } from "../../../ui-kit/dropdown-menu/dropdown-menu.component";
import { useBoolToggle } from "../../../hooks/useModal";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { UpdateServerModal } from "../update-server-modal/update-server-modal.component";

const validationSchema = yup.object().shape({
  ip: yup.string().required(),
  mongoHost: yup.string().required(),
});

export const ServersTable = observer(() => {
  const serverService = useServerService();
  const authService = useAuthService();

  useEffect(() => {
    (async () => {
      try {
        await serverService.getAllServers();
      } catch (err) {
        const error = err as AxiosError;

        toast.error(error.response?.data?.message || error.message);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [isOpened, openModal, closeModal] = useBoolToggle();
  const { reset, ...updateServerForm } = useForm<UpdateServerFormValues>({
    resolver: yupResolver(validationSchema),
  });

  const columns = useMemo(() => {
    return [
      {
        Header: "Server IP",
        Cell: (cell: CellProps<Server>) => {
          const server = cell.row.original;

          return <a href={`ssh://root@${server.ip}`}>{server.ip}</a>;
        },
      },
      {
        Header: "Mongo Host",
        accessor: "mongoHost" as const,
      },
      {
        Header: "Total opened bots",
        Cell: (cell: CellProps<Server>) => {
          const server = cell.row.original;

          return `${server.places.total}`;
        },
      },
      {
        Header: "Action",
        Cell: (cell: CellProps<Server>) => {
          const server = cell.row.original;
          const dropdownItems = [];

          if (authService.permissions.includes(UserPermissions.UPDATE_SERVER)) {
            dropdownItems.push({
              label: "Update server",
              icon: rotate360,
              onClick: () => {
                serverService.selectServer(server);
                reset();
                openModal();
              },
            });
          }

          return <DropdownMenu items={dropdownItems} />;
        },
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Table
        columns={columns}
        data={serverService.servers}
        tableKey="servers"
      />
      <UpdateServerModal
        isOpened={isOpened}
        closeModal={closeModal}
        form={updateServerForm}
      />
    </>
  );
});
