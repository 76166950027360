import { observer } from "mobx-react";
import { TelegramAccountTable } from "../telegram-account-table/telegram-account-table.component";
import { CreateTelegramAccount } from "../create-telegram-account/create-telegram-account.component";

export const TelegramAccountList = observer(() => {
  return (
    <>
      <CreateTelegramAccount />
      <TelegramAccountTable />
    </>
  );
});
