import React, { useRef } from "react";
import { CellProps } from "react-table";
import { toast } from "react-toastify";
import lockIcon from "@iconify-icons/uil/lock";
import unlockIcon from "@iconify-icons/uil/unlock";
import redoIcon from "@iconify-icons/uil/redo";
import keySkeletonAlt from "@iconify-icons/uil/key-skeleton-alt";
import playIcon from "@iconify-icons/uil/play";
import languageIcon from "@iconify-icons/uil/language";
import { DropdownMenu } from "../../../ui-kit/dropdown-menu/dropdown-menu.component";
import { useBotService } from "../../bot.service";
import { Bot } from "../../bot.types";
import { useAuthService } from "../../../auth/auth.service";
import { UserPermissions } from "../../../user/user.types";
import { AxiosError } from "axios";
import { FileInput } from "./table-action-column.styles";

type TableActionColumnProps = CellProps<Bot> & {
  openClosingMessageModal: VoidFunction;
  openBotConfigModal: VoidFunction;
};

export const TableActionColumn: React.FC<TableActionColumnProps> = ({
  openClosingMessageModal,
  openBotConfigModal,
  ...cell
}) => {
  const botService = useBotService();
  const authService = useAuthService();

  const bot = cell.row.original;

  const fileInputRef = useRef<HTMLInputElement>(null);

  const onOpenClick = async () => {
    // eslint-disable-next-line no-restricted-globals
    const validateSubmit = confirm(
      `Are you sure that you want to open ${bot.botName}?`
    );

    if (!validateSubmit) return;

    try {
      await botService.openBot(bot.id);
      toast.success(`${bot.botName} is opened!`);
    } catch (e) {
      const error = e as AxiosError;
      toast.error(error.response?.data?.message || error.message);
    }
  };

  const onCloseClick = () => {
    botService.selectBot(bot.id);
    openClosingMessageModal();
  };

  const onOpenCloseClick = bot.isClosed ? onOpenClick : onCloseClick;

  const onRestartClick = async () => {
    try {
      await botService.restartBot(bot.id);
      toast.success(`${bot.botName} is restarted!`);
    } catch (e) {
      const error = e as AxiosError;
      toast.error(error.response?.data?.message || error.message);
    }
  };

  const onChangeConfigClick = async () => {
    botService.selectBot(bot.id);
    openBotConfigModal();
  };

  const onTurnOnOffClick = async () => {
    try {
      await botService.turnOnOff(bot.id);
      toast.success(`${bot.botName} is turned ${bot.turnedOn ? "off" : "on"}!`);
    } catch (e) {
      const error = e as AxiosError;
      toast.error(error.response?.data?.message || error.message);
    }
  };

  const onDownloadLanguageFile = async () => {
    try {
      const blob = await botService.downloadFile(bot.id);
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${bot.botName}.js`);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (e) {
      const error = e as AxiosError;
      toast.error(error.response?.data?.message || error.message);
    }
  };

  const onUploadLanguageFile = () => {
    fileInputRef.current?.click();
  };

  const onInputFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (!event.target.files) {
      toast.error("No files selected");
      return;
    }

    const [file] = Array.from(event.target.files);

    if (!file) {
      toast.error("No files selected");
      return;
    }

    try {
      await botService.uploadFile(bot.id, file);
      toast.success(`Language file updated for ${bot.botName}!`);
    } catch (e) {
      const error = e as AxiosError;
      toast.error(error.response?.data?.message || error.message);
    }
  };

  const items = [];

  if (authService.permissions.includes(UserPermissions.UPDATE_BOT)) {
    items.push(
      {
        icon: cell.row.original.isClosed ? unlockIcon : lockIcon,
        label: cell.row.original.isClosed ? "Open" : "Close",
        onClick: onOpenCloseClick,
      },
      {
        icon: redoIcon,
        label: "Pull & Restart",
        onClick: onRestartClick,
      },
      {
        icon: keySkeletonAlt,
        label: "Change config",
        onClick: onChangeConfigClick,
      },
      {
        icon: playIcon,
        label: "Turn on/off",
        onClick: onTurnOnOffClick,
      },
      {
        icon: languageIcon,
        label: "Download language file",
        onClick: onDownloadLanguageFile,
      },
      {
        icon: languageIcon,
        label: "Upload language file",
        onClick: onUploadLanguageFile,
      }
    );
  }

  return (
    <>
      <DropdownMenu items={items} rightSide />
      <FileInput type="file" ref={fileInputRef} onChange={onInputFileChange} />
    </>
  );
};
