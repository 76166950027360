import { api } from "../config/api";
import { InvoiceResponseDto } from "./dto/get-invoice-response.dto";
import { SystemConfigResponseDto } from "./dto/get-system-config-response.dto";
import { Invoice } from "./invoice.types";

export interface InvoiceRepo {
  updateId(id: string): Promise<void>;
  getConfig(): Promise<string>;
  getInvoice(): Promise<Invoice | null>;
}

export const HTTP_INVOICE_REPO = "HTTP_INVOICE_REPO";

export class HttpInvoiceRepo implements InvoiceRepo {
  async updateId(id: string): Promise<void> {
    await api.patch("/api/system-config", {
      currentMonthInvoice: id,
    });
  }

  async getConfig(): Promise<string> {
    const { data } = await api.get<SystemConfigResponseDto>(
      "/api/system-config"
    );

    return data.currentMonthInvoice;
  }

  async getInvoice(): Promise<Invoice | null> {
    const { data } = await api.get<InvoiceResponseDto>("/api/invoice");

    if(!data.items) {
      return null;
    }

    return {
      title: data.title,
      items: data.items.map(({ _id, ...item }) => ({
        id: _id,
        ...item,
      })),
      total: data.total,
      paid: data.paid,
    };
  }
}
