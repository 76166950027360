import { useState } from "react";

export const useBoolToggle = (
  defaultValue = false
): [boolean, () => void, () => void, () => void] => {
  const [isOpened, setIsOpened] = useState(false);

  const openModal = () => setIsOpened(true);

  const closeModal = () => setIsOpened(false);

  const toggle = () => setIsOpened((v) => !v);

  return [isOpened, openModal, closeModal, toggle];
};
