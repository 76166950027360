import React from "react";
import { useForm } from "react-hook-form";
import { useAuthService } from "../../../auth/auth.service";
import { useBoolToggle } from "../../../hooks/useModal";
import { Button } from "../../../ui-kit/button/button.styles";
import { CreateButtonWrapper, FormWrapper } from "../../../ui-kit/styles";
import { UserPermissions } from "../../../user/user.types";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import ipRegex from "ip-regex";
import { useServerService } from "../../server.service";
import { toast } from "react-toastify";
import { AxiosError } from "axios";
import { InlineIcon } from "@iconify/react";
import focusAddIcon from "@iconify-icons/uil/focus-add";
import { Modal } from "../../../ui-kit/modal/modal.component";
import { Input } from "../../../ui-kit/input/input.component";

const addServerSchemaValidation = yup.object().shape({
  ip: yup.string().required(),
  mongoHost: yup.string().required(),
});

type AddServerFormValues = {
  ip: string;
  mongoHost: string;
};

export const CreateServerModal: React.FC<{}> = () => {
  const authService = useAuthService();
  const serverService = useServerService();

  const [isOpened, openModal, closeModal] = useBoolToggle();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm<AddServerFormValues>({
    resolver: yupResolver(addServerSchemaValidation),
  });
  const onSubmit = async (values: AddServerFormValues) => {
    if (!ipRegex({ exact: true }).test(values.ip)) {
      setError("ip", {
        message: "Wrong IP format",
      });
      return;
    }

    try {
      await serverService.createServer(values);
      toast.success(`Server ${values.ip} has been added!`);
      closeModal();
    } catch (e) {
      const error = e as AxiosError;
      toast.error(error.response?.data?.message || error.message);
    }
  };
  const modalFooter = (
    <Button size="sm" onClick={handleSubmit(onSubmit)}>
      Add server
    </Button>
  );

  if (!authService.permissions.includes(UserPermissions.CREATE_SERVER)) {
    return null;
  }

  return (
    <>
      <CreateButtonWrapper>
        <Button onClick={openModal}>
          <InlineIcon icon={focusAddIcon} width="28" /> Add server
        </Button>
      </CreateButtonWrapper>
      <Modal
        isOpened={isOpened}
        closeModal={closeModal}
        title={"Add server"}
        footer={modalFooter}
      >
        <FormWrapper>
          <Input
            label="IP"
            register={register}
            name="ip"
            error={errors["ip"]?.message}
          />
          <Input
            label="MongoDB Host"
            register={register}
            name="mongoHost"
            error={errors["mongoHost"]?.message}
          />
        </FormWrapper>
      </Modal>
    </>
  );
};
