import styled from "styled-components";

export const LogoStyled = styled.div`
  display: flex;
  align-items: center;

  svg {
    margin-right: 16px;
  }
`;
