import { opacify } from "polished";
import styled from "styled-components";

type FilterBarContainerProps = {
  isVisible: boolean;
};

export const FilterBarContainer = styled.div<FilterBarContainerProps>`
  background-color: ${(p) => opacify(-0.5, p.theme.colors.widgetBorderColor)};
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9;
  backdrop-filter: blur(4px);
  display: ${(p) => (p.isVisible ? "block" : "none")};
`;

export const FilterSidebar = styled.div`
  width: 352px;
  height: 100%;
  background-color: ${(p) => p.theme.colors.primary};
  position: absolute;
  right: 0;
  padding: 34px 48px;
`;

export const FilterBarHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 56px;

  svg {
    margin-right: 16px;
  }
`;

export const FilterBarContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

export const FilterBarBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;
