import styled from "styled-components";
import { InputWrapper } from "./input/input.styles";

export const CreateButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 40px;
`;

export const FormWrapper = styled.div`
  ${InputWrapper}:not(:last-of-type) {
    margin-bottom: 40px;
  }
`;
