import styled from "styled-components";

export const BotsListWrapper = styled.div`
  .search-input {
    margin-bottom: 40px;
  }
`;

export const FilterSearchBar = styled.div`
  display: flex;
  justify-content: space-between;
  column-gap: 24px;
`;

export const DownloadReportWrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;
  margin-bottom: 24px;
`;
