export type Bot = {
  id: string;
  botName: string;
  closingMessage: string;
  isClosed: boolean;
  botToken: string;
  groupName: string;
  channelName: string;
  token: string;
  perReferral: number;
  referralLimit: number;
  decimals: number;
  mandatoryReward: number;
  perToken: number;
  turnedOn: boolean;
  ownerName: string;
  hostIp: string;
  advertiser: string;
  creator: string;
  updatedAt: Date;
};

export interface BotOwner {
  _id: string;
  name: string;
  phoneNumber: string;
  createdAt: Date;
  updatedAt: Date;
  __v: number;
}

interface Creator {
  permissions: string[];
  _id: string;
  email: string;
  createdAt: string;
  updatedAt: string;
  __v: number;
}
export interface BotResponse {
  _id: string;
  botToken: string;
  botName: string;
  perReferral: number;
  referralLimit: number;
  redisHost: string;
  redisPort: number;
  mongoUser: string;
  mongoPassword: string;
  mongoHost: string;
  mongoPort: number;
  mongoTable: string;
  languages: string;
  decimals: number;
  captcha: string;
  mathType: string;
  mathMin: number;
  mathMax: number;
  groupName: string;
  channelName: string;
  refDisabled: boolean;
  preCaptcha: boolean;
  advertiser: string;
  admins: string;
  moderators: string;
  mandatoryReward: number;
  captchaAttempts: number;
  perToken: number;
  token: string;
  listDownload: string;
  configVersion: number;
  hostIp: string;
  createdAt: string;
  updatedAt: string;
  __v: number;
  isClosed: boolean;
  closedMessage: string;
  sgMandatory: boolean;
  turnedOn: boolean;
  owner?: BotOwner;
  creator?: Creator;
}

export enum FilterOpenClosed {
  ALL = "All",
  OPENED = "Only opened",
  CLOSED = "Only closed",
}

export type BotConfigFormValues = {
  botToken: string;
  botName: string;
  groupName: string;
  channelName: string;
  token: string;
  perReferral: number;
  referralLimit: number;
  decimals: number;
  mandatoryReward: number;
  perToken: number;
  advertiser: string;
};

export type LatestBots = {
  detective: string;
  ico: string;
  airdrop: string;
};
