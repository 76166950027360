import { AxiosError } from "axios";
import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { useAuthService } from "../../../auth/auth.service";
import { Button } from "../../../ui-kit/button/button.styles";
import { Input } from "../../../ui-kit/input/input.component";
import { UserPermissions } from "../../../user/user.types";
import { useInvoiceService } from "../../invoice.service";
import { InvoiceIDInputWrapper } from "../invoices-list/invoices-list.styles";

export const UpdateInvoiceId = () => {
  const authService = useAuthService();
  const invoiceService = useInvoiceService();

  const invoiceRef = useRef<HTMLInputElement>(null);

  const onSubmitInvoiceId = async () => {
    try {
      await invoiceService.updateInvoiceId(invoiceRef.current?.value ?? "");
    } catch (e) {
      const error = e as AxiosError;

      toast.error(
        Array.isArray(error.response?.data?.message)
          ? error.response?.data?.message[0]
          : error.response?.data?.message || error.message
      );
    }
  };
  useEffect(() => {
    (async () => {
      try {
        await invoiceService.getInvoiceId();
      } catch (e) {
        //
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!invoiceRef.current) return;
    invoiceRef.current.value = invoiceService.invoiceId;
  }, [invoiceService.invoiceId]);

  return authService.permissions.includes(
    UserPermissions.UPDATE_SYSTEM_CONFIG
  ) ? (
    <InvoiceIDInputWrapper>
      <Input placeholder="Unpaidly invoice ID" ref={invoiceRef} />
      <Button onClick={onSubmitInvoiceId}>Save</Button>
    </InvoiceIDInputWrapper>
  ) : null;
};
