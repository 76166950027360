export type User = {
  id: string;
  email: string;
  permissions: UserPermissions[];
};

export enum UserPermissions {
  VIEW_BOTS = "VIEW_BOTS",
  CREATE_BOT = "CREATE_BOT",
  UPDATE_BOT = "UPDATE_BOT",
  DEPLOY_BOT = "DEPLOY_BOT",
  REPORTS_BOT = "REPORTS_BOT",

  VIEW_USERS = "VIEW_USERS",
  CREATE_USER = "CREATE_USER",
  DELETE_USER = "DELETE_USER",
  UPDATE_USER = "UPDATE_USER",

  CREATE_SERVER = "CREATE_SERVER",
  VIEW_SERVERS = "VIEW_SERVERS",
  UPDATE_SERVER = "UPDATE_SERVER",

  UPDATE_SYSTEM_CONFIG = "UPDATE_SYSTEM_CONFIG",

  VIEW_INVOICES = "VIEW_INVOICES",

  CREATE_TELEGRAM_ACCOUNT = "CREATE_TELEGRAM_ACCOUNT",
  VIEW_TELEGRAM_ACCOUNTS = "VIEW_TELEGRAM_ACCOUNTS",
  UPDATE_TELEGRAM_ACCOUNTS = "UPDATE_TELEGRAM_ACCOUNTS",
  DELETE_TELEGRAM_ACCOUNTS = "DELETE_TELEGRAM_ACCOUNTS",
}

export type UpdateUserFormValues = {
  permissions: {
    value: boolean;
    label: UserPermissions;
  }[];
};
