import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  SignInBackground,
  SignInFormWrapper,
  SignInLeftSide,
  SignInRightSide,
  SignInWrapper,
} from "./sign-in.styles";
import { ReactComponent as People } from "../../../assets/images/sign-in-people.svg";
import { Typography } from "../../../ui-kit/typography/typography.component";
import { Input } from "../../../ui-kit/input/input.component";
import { Button } from "../../../ui-kit/button/button.styles";
import { useAuthService } from "../../auth.service";
import { useHistory } from "react-router";
import { Logo } from "../../../ui-kit/logo/logo.component";

type SignInFormValues = {
  email: string;
  password: string;
};

const signInValidationSchema = yup.object().shape({
  email: yup.string().email().required(),
  password: yup.string().required(),
});

export const SignIn = () => {
  const store = useAuthService();
  const history = useHistory();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<SignInFormValues>({
    resolver: yupResolver(signInValidationSchema),
  });
  const onSubmit = async (values: SignInFormValues) => {
    try {
      await store.login(values.email, values.password);

      history.push("/");
    } catch (e) {
      // do something
    }
  };

  return (
    <SignInWrapper>
      <SignInBackground />
      <SignInLeftSide>
        <Logo />
        <People className="people" />
      </SignInLeftSide>
      <SignInRightSide>
        <Typography
          variant="h1"
          fontSize={32}
          resetMargin
          className="sign-in-title"
        >
          Sign in to your account
        </Typography>
        <SignInFormWrapper onSubmit={handleSubmit(onSubmit)} noValidate>
          <Input
            label="Email"
            placeholder="Email"
            type="email"
            register={register}
            name="email"
            error={errors["email"]?.message}
          />
          <Input
            label="Password"
            placeholder="Password"
            type="password"
            register={register}
            name="password"
            error={errors["password"]?.message}
          />
          <Button fullWidth justifyContent="center">
            Sign In
          </Button>
        </SignInFormWrapper>
      </SignInRightSide>
    </SignInWrapper>
  );
};
