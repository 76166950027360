import { observer } from "mobx-react";
import { useBotService } from "../../bot.service";
import { useBoolToggle } from "../../../hooks/useModal";
import { BotsListTable } from "../bots-list-table/bots-list-table.component";
import { BotsListWrapper, DownloadReportWrapper } from "./bots-list.styles";
import { CloseBotModal } from "../close-bot-modal/close-bot-modal.component";
import { DropdownMenu } from "../../../ui-kit/dropdown-menu/dropdown-menu.component";
import { ChangeBotConfigModal } from "../change-bot-config-modal/change-bot-config-modal.component";
import { BotTablePagination } from "../bot-table-pagination/bot-table-pagination.component";
import { AxiosError } from "axios";
import { toast } from "react-toastify";
import { useAuthService } from "../../../auth/auth.service";
import { UserPermissions } from "../../../user/user.types";
import { BotFilter } from "../bot-filter/bot-filter.component";

export const BotsList = observer(() => {
  const botService = useBotService();
  const authService = useAuthService();

  const [
    isClosingMessageModalOpened,
    openClosingMessageModal,
    closeClosingMessageModal,
  ] = useBoolToggle();

  const [isBotConfigModalOpened, openBotConfigModal, closeBotConfigModal] =
    useBoolToggle();

  const downloadReportItems = [
    {
      label: "Closed bots with uptime > 30 days",
      onClick: async () => {
        try {
          const blob = await botService.downloadReport();
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `report.pdf`);
          document.body.appendChild(link);
          link.click();
          link.remove();
        } catch (e) {
          const error = e as AxiosError;
          toast.error(error.response?.data?.message || error.message);
        }
      },
    },
  ];

  return (
    <BotsListWrapper>
      {authService.permissions.includes(UserPermissions.REPORTS_BOT) && (
        <DownloadReportWrapper>
          <DropdownMenu
            items={downloadReportItems}
            placeholder="Download report"
            isButton
          />
        </DownloadReportWrapper>
      )}
      <BotFilter />
      <BotsListTable
        openClosingMessageModal={openClosingMessageModal}
        openBotTokenModal={openBotConfigModal}
      />
      {botService.totalPages > 1 && <BotTablePagination />}
      <CloseBotModal
        isOpened={isClosingMessageModalOpened}
        closeModal={closeClosingMessageModal}
      />
      <ChangeBotConfigModal
        isOpened={isBotConfigModalOpened}
        closeModal={closeBotConfigModal}
      />
    </BotsListWrapper>
  );
});
