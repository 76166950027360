import { observer } from "mobx-react";
import { useEffect, useMemo } from "react";
import { CellProps } from "react-table";
import trashAlt from "@iconify-icons/uil/trash-alt";
import { useAuthService } from "../../../auth/auth.service";
import { Table } from "../../../ui-kit/table/table.component";
import { UserPermissions } from "../../../user/user.types";
import { useTelegramAccountService } from "../../telegram-account.service";
import { TelegramAccount } from "../../telegram-account.types";
import { toast } from "react-toastify";
import { AxiosError } from "axios";
import { DropdownMenu } from "../../../ui-kit/dropdown-menu/dropdown-menu.component";

export const TelegramAccountTable = observer(() => {
  const telegramAccountService = useTelegramAccountService();
  const authService = useAuthService();
  const canDrag = authService.permissions.includes(
    UserPermissions.UPDATE_TELEGRAM_ACCOUNTS
  );

  useEffect(() => {
    telegramAccountService.getAllAccounts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const reorderAccounts = async (dragId: string, dropId: string) => {
    try {
      await telegramAccountService.reorderAccounts(dragId, dropId);
      await telegramAccountService.getAllAccounts();
      toast.success(`Order updated!`);
    } catch (e) {
      const error = e as AxiosError;
      toast.error(
        Array.isArray(error.response?.data?.message)
          ? error.response?.data?.message[0]
          : error.response?.data?.message || error.message
      );
    }
  };

  const columns = useMemo(() => {
    return [
      {
        Header: "Name",
        accessor: "name" as const,
      },
      {
        Header: "Phone Number",
        accessor: "phoneNumber" as const,
      },
      {
        Header: "Action",
        Cell: (cell: CellProps<TelegramAccount>) => {
          const server = cell.row.original;
          const dropdownItems = [];

          const onDeleteAccount = async () => {
            try {
              await telegramAccountService.delete(server.id);
              toast.success(`${server.name} is deleted!`);
              await telegramAccountService.getAllAccounts();
            } catch (e) {
              const error = e as AxiosError;
              toast.error(error.response?.data?.message || error.message);
            }
          };

          if (
            authService.permissions.includes(
              UserPermissions.DELETE_TELEGRAM_ACCOUNTS
            )
          ) {
            dropdownItems.push({
              label: "Delete account",
              icon: trashAlt,
              onClick: onDeleteAccount,
            });
          }

          return <DropdownMenu items={dropdownItems} />;
        },
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Table
      columns={columns}
      data={telegramAccountService.accounts}
      dropCallback={canDrag ? reorderAccounts : undefined}
      tableKey="telegram-accounts"
    />
  );
});
