import { BrowserRouter, Redirect, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { observer } from "mobx-react";
import { GlobalStyles } from "./ui-kit/global-styles/global-styles.styles";
import { AuthEntrypoint } from "./auth/auth.entrypoint";
import { useAuthService } from "./auth/auth.service";
import { Dashboard } from "./ui-kit/dashboard/dashboard.component";
import { BotEntrypoint } from "./bot/bot.entrypoint";
import { UserPermissions } from "./user/user.types";
import { UserEntypoint } from "./user/user.entrypoint";
import { Typography } from "./ui-kit/typography/typography.component";
import { ServerEntrypoint } from "./servers/server.entrypoint";
import { DashboardEntrypoint } from "./dashboard/dashboard.entrypoint";
import { InvoicesEntrypoint } from "./invoices/invoices.entrypoint";
import { TelegramAccountEntrypoint } from "./telegram-accounts/telegram-account.entrypoint";

export const App = observer(() => {
  const authService = useAuthService();

  if (authService.authToken === null) {
    return <Typography>Loading</Typography>;
  }

  return (
    <>
      <GlobalStyles />
      <BrowserRouter>
        {authService.authToken ? (
          <Switch>
            <Dashboard>
              <DashboardEntrypoint />
              {authService.permissions.includes(UserPermissions.VIEW_BOTS) && (
                <BotEntrypoint />
              )}
              {authService.permissions.includes(UserPermissions.VIEW_USERS) && (
                <UserEntypoint />
              )}
              {authService.permissions.includes(
                UserPermissions.VIEW_SERVERS
              ) && <ServerEntrypoint />}
              {authService.permissions.includes(
                UserPermissions.VIEW_INVOICES
              ) && <InvoicesEntrypoint />}
              {authService.permissions.includes(
                UserPermissions.VIEW_TELEGRAM_ACCOUNTS
              ) && <TelegramAccountEntrypoint />}
            </Dashboard>
            <Redirect to="/" />
          </Switch>
        ) : (
          <AuthEntrypoint />
        )}
      </BrowserRouter>
      <ToastContainer />
    </>
  );
});
