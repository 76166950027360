import styled from "styled-components";

export type InputStyledProps = {
  additionalPadding: boolean;
};

export const InputStyled = styled.input<InputStyledProps>`
  width: 100%;
  height: 60px;
  border: 1px solid ${(p) => p.theme.colors.textLineDark};
  border-radius: 14px;
  padding: ${(p) => (p.additionalPadding ? 20 : 0)}px 16px;
  font-size: 18px;
  color: ${(p) => p.theme.colors.textBlack};
  background-color: ${(p) => p.theme.colors.inputBackground};

  ::placeholder {
    color: ${(p) => p.theme.colors.textGrey};
    font-weight: 500;
  }
`;

export const InputWrapper = styled.div`
  width: 100%;
  position: relative;

  label {
    margin-bottom: 16px;
  }

  .input-error {
    position: absolute;
    margin-top: 4px;
    color: ${(p) => p.theme.colors.secondaryRed};
  }
`;
