import styled from "styled-components";

export const PaginationWrapper = styled.div`
  .pagination-container {
    list-style: none;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }

  .pagination-item {
    border: 1px solid ${(p) => p.theme.colors.primaryPurple};
    color: ${(p) => p.theme.colors.primaryPurple};
    border-radius: 14px;
    cursor: pointer;
    transition: all 0.3s ease;

    a {
      display: block;
      padding: 12px;
    }

    &:hover {
      background-color: ${(p) => p.theme.colors.primaryPurple};
      color: ${(p) => p.theme.colors.primary};
    }
  }

  .pagination-item-disabled {
    background-color: ${(p) => p.theme.colors.textLineDark};
    border-color: ${(p) => p.theme.colors.textLineDark};
    color: ${(p) => p.theme.colors.textGrey};
    cursor: not-allowed;

    &:hover {
      background-color: ${(p) => p.theme.colors.textLineDark};
      color: ${(p) => p.theme.colors.textGrey};
    }
  }

  .pagination-item-active {
    background-color: ${(p) => p.theme.colors.primaryPurple};
    color: ${(p) => p.theme.colors.primary};
  }
`;
