import { useDragLayer, XYCoord } from "react-dnd";
import { Cell } from "react-table";
import { TableCell, TableRow } from "../table/table.styles";
import { TableDragLayerStyled } from "./table-row-drag-layer.styles";

export const TableRowDragLayer = () => {
  const { isDragging, item, initialOffset, currentOffset } = useDragLayer(
    (monitor) => ({
      item: monitor.getItem(),
      itemType: monitor.getItemType(),
      initialOffset: monitor.getInitialSourceClientOffset(),
      currentOffset: monitor.getSourceClientOffset(),
      isDragging: monitor.isDragging(),
    })
  );

  const getItemStyles = (
    initialOffset: XYCoord | null,
    currentOffset: XYCoord | null
  ) => {
    if (!initialOffset || !currentOffset) {
      return {
        display: "none",
      };
    }

    return {
      left: currentOffset.x + 100,
      top: currentOffset.y,
    };
  };

  if (!isDragging) {
    return null;
  }

  const { row } = item;

  return (
    <TableDragLayerStyled
      style={getItemStyles(initialOffset, currentOffset)}
      cellSpacing={0}
      cellPadding={0}
    >
      <tbody>
        <TableRow {...row.getRowProps()} isDragLayer>
          {row.cells
            .filter((cell: Cell<any, any>) => cell.column.Header !== "Action")
            .map((cell: Cell<any, any>) => (
              <TableCell {...cell.getCellProps()}>
                {cell.render("Cell")}
              </TableCell>
            ))}
        </TableRow>
      </tbody>
    </TableDragLayerStyled>
  );
};
