import React, { useEffect, useMemo } from "react";
import { observer } from "mobx-react";
import trashAlt from "@iconify-icons/uil/trash-alt";
import { CellProps } from "react-table";
import { toast } from "react-toastify";
import { Table } from "../../../ui-kit/table/table.component";
import { useUserService } from "../../user.service";
import { DropdownMenu } from "../../../ui-kit/dropdown-menu/dropdown-menu.component";
import { User, UserPermissions } from "../../user.types";
import { useAuthService } from "../../../auth/auth.service";
import rotate360 from "@iconify-icons/uil/rotate-360";
import { AxiosError } from "axios";

type UsersTableProps = {
  openUpdateUserModal: () => void;
};

export const UsersTable: React.FC<UsersTableProps> = observer(
  ({ openUpdateUserModal }) => {
    const userService = useUserService();
    const authService = useAuthService();

    useEffect(() => {
      (async () => {
        try {
          await userService.getAllUsers();
        } catch (error) {
          const err = error as AxiosError;
          toast.error(err.response?.data?.message || err.message);
        }
      })();

      return () => userService.cleanService();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const columns = useMemo(() => {
      return [
        {
          Header: "ID",
          accessor: "id" as const,
        },
        {
          Header: "Email",
          accessor: "email" as const,
        },
        {
          Header: "Action",
          Cell: (cell: CellProps<User>) => {
            const user = cell.row.original;

            const deleteUser = async () => {
              try {
                await userService.delete(user.id);
                toast.success(`${user.email} is deleted!`);
              } catch (error) {
                const err = error as AxiosError;
                toast.error(err.response?.data?.message || err.message);
              }
            };

            const dropdownItems = [];

            if (
              authService.permissions.includes(UserPermissions.DELETE_USER) &&
              authService.userId !== user.id
            ) {
              dropdownItems.push({
                label: "Delete user",
                icon: trashAlt,
                onClick: deleteUser,
              });
            }

            if (authService.permissions.includes(UserPermissions.UPDATE_USER)) {
              dropdownItems.push({
                label: "Update user",
                icon: rotate360,
                onClick: () => {
                  userService.selectUser(user);
                  openUpdateUserModal();
                },
              });
            }

            return <DropdownMenu items={dropdownItems} />;
          },
        },
      ];
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
      <Table columns={columns} data={userService.users} tableKey="users" />
    );
  }
);
