import { InlineIcon } from "@iconify/react";
import ellipsisIcon from "@iconify-icons/uil/ellipsis-h";
import angleDownIcon from "@iconify-icons/uil/angle-down";
import {
  DropdownMenuButton,
  DropdownMenuStyled,
  DropdownMenuWrapper,
} from "./dropdown-menu.styles";
import { Button } from "../button/button.styles";
import { Typography } from "../typography/typography.component";
import { useOutsideClick } from "../../hooks/useOutsideClick";
import { useBoolToggle } from "../../hooks/useModal";
import { useMemoId } from "../../hooks/useMemoId";

type DropdownMenuProps = {
  items: {
    label: string;
    onClick: () => void;
    icon?: object;
    buttonWidth?: number;
  }[];
  placeholder?: string;
  fullWidth?: boolean;
  rightSide?: boolean;
  isButton?: boolean;
  maxHeight?: number | string;
};

export const DropdownMenu: React.FC<DropdownMenuProps> = ({
  items,
  placeholder,
  fullWidth,
  rightSide,
  isButton,
  maxHeight,
}) => {
  const [isOpened, , close, toggleMenu] = useBoolToggle();
  const ref = useOutsideClick(close);
  const menuId = useMemoId();

  const onItemClick = (cb: () => void) => () => {
    cb();
    toggleMenu();
  };

  return (
    <DropdownMenuWrapper ref={ref}>
      {isButton ? (
        <Button onClick={toggleMenu} noIconMargin>
          {placeholder} <InlineIcon icon={angleDownIcon} width={28} />
        </Button>
      ) : (
        <DropdownMenuButton
          onClick={toggleMenu}
          isActive={isOpened}
          fullWidth={fullWidth!}
        >
          {placeholder ? (
            <Typography variant="h7">{placeholder}</Typography>
          ) : (
            <InlineIcon icon={ellipsisIcon} width={28} />
          )}
        </DropdownMenuButton>
      )}
      <DropdownMenuStyled
        isVisible={isOpened}
        fullWidth={fullWidth!}
        rightSide={rightSide!}
        style={{ maxHeight }}
      >
        {items.length === 0 && (
          <Typography variant="h4" resetMargin>
            No actions available
          </Typography>
        )}
        {items.map((item, index) => (
          <Button
            size="sm"
            variant="transparent"
            onClick={onItemClick(item.onClick)}
            key={`${menuId}-${index}`}
            style={{ minWidth: item.buttonWidth }}
            fullWidth
          >
            {item.icon && <InlineIcon icon={item.icon} />} {item.label}
          </Button>
        ))}
      </DropdownMenuStyled>
    </DropdownMenuWrapper>
  );
};

DropdownMenu.defaultProps = {
  fullWidth: false,
  maxHeight: "unset",
};
