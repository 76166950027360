import { AxiosError } from "axios";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { useBotService } from "../bot.service";

export const useFetchBots = () => {
  const botService = useBotService();

  useEffect(() => {
    (async () => {
      try {
        await botService.fetchBots();
      } catch (e) {
        const error = e as AxiosError;

        toast.error(
          Array.isArray(error.response?.data?.message)
            ? error.response?.data?.message[0]
            : error.response?.data?.message || error.message
        );
      }
    })();

    return () => botService.cleanBotsListData();
  }, [botService]);
};
