import { createPortal } from "react-dom";
import { Button } from "../../../ui-kit/button/button.styles";
import { InlineIcon } from "@iconify/react";
import userPlusIcon from "@iconify-icons/uil/user-plus";
import React, { useLayoutEffect, useState } from "react";
import { CreateButtonWrapper } from "../../../ui-kit/styles";

type CreateUserBtnProps = {
  openCreateUserModal: () => void;
};

export const CreateUserBtn: React.FC<CreateUserBtnProps> = ({
  openCreateUserModal,
}) => {
  const [canRender, setCanRender] = useState(false);
  useLayoutEffect(() => {
    setCanRender(true);
  }, []);

  if (!canRender) return null;

  return createPortal(
    <CreateButtonWrapper>
      <Button onClick={openCreateUserModal}>
        <InlineIcon icon={userPlusIcon} width="28" /> Create user
      </Button>
    </CreateButtonWrapper>,
    document.getElementById("create-user-button")!
  );
};
