import React, { createContext, useState } from "react";
import { ThemeProvider } from "styled-components";
import { darkTheme, lightTheme } from "./theme";

export const themeContext = createContext({
  theme: "light",
  onThemeToggl: () => {},
});

const themes = {
  light: lightTheme,
  dark: darkTheme,
};

type ThemeValue = "light" | "dark";

export const MyThemeProvider: React.FC<{}> = ({ children }) => {
  const [theme, setTheme] = useState<ThemeValue>(
    (localStorage.getItem("theme") as ThemeValue) || "light"
  );
  const onThemeToggl = () => {
    const newTheme = theme === "light" ? "dark" : "light";
    setTheme(newTheme);
    localStorage.setItem("theme", newTheme);
  };

  return (
    <themeContext.Provider value={{ theme, onThemeToggl }}>
      <ThemeProvider theme={themes[theme]}>{children}</ThemeProvider>
    </themeContext.Provider>
  );
};
