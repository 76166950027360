import React, { useEffect, useState } from "react";
import { Input } from "../../../ui-kit/input/input.component";
import { useBotService } from "../../bot.service";

export const BotSearchInput = () => {
  const store = useBotService();

  const [search, setSearch] = useState("NO_NEED_TO_REQUEST");
  const onChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };
  useEffect(() => {
    if (search === "NO_NEED_TO_REQUEST") return;
    store.search(search);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  return (
    <Input
      placeholder="Search"
      wrapperClassName="search-input"
      onChange={onChangeSearch}
    />
  );
};
