import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`
  html, body, #root {
    font-family: ${(p) => p.theme.fonts.primary};
    height: 100%;
    color: ${(p) => p.theme.colors.textBlack};
    background-color: ${(p) => p.theme.colors.primary};
  }

  * {
    box-sizing: border-box;
    outline: none;
  }

  a {
    color: ${(p) => p.theme.colors.linkColor};
    
    &:hover {
      color: ${(p) => p.theme.colors.primaryPurple};
    }
  }
`;
