import styled from "styled-components";

interface InformerStyledProps {
  size?: "m" | "l";
}

const informerSize = 225;

export const InformerStyled = styled.div<InformerStyledProps>`
  border: 2px solid ${(p) => p.theme.colors.widgetBorderColor};
  border-radius: 24px;
  width: ${(p) =>
    p.size === "l" ? `${informerSize * 2 + 28}px` : `${informerSize}px`};
  height: 300px;
  display: flex;
  justify-content: space-between;
  padding: 20px;
  flex-direction: column;

  .places {
    margin-bottom: 8px;
  }
`;
