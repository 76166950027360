import { Route } from "react-router";
import { BotsList } from "./components/bots-list/bots-list.component";

export const BotEntrypoint = () => {
  return (
    <>
      <Route path="/bots" component={BotsList} />
    </>
  );
};
