import { action, makeObservable, observable } from "mobx";
import { injector } from "../Injector";
import { HTTP_USER_REPO, UserRepo } from "./user.repo";
import { User } from "./user.types";
import { CreateUserRequestDTO } from "./dto/create-user-request-dto";
import { UpdateUserRequestDTO } from "./dto/update-user-request.dto";

export interface UserService {
  users: User[];
  selectedUser: User | null;

  getAllUsers(): Promise<void>;
  register(values: CreateUserRequestDTO): Promise<void>;
  cleanService(): void;
  delete(id: string): void;
  selectUser(user: User): void;
  update(id: string, values: UpdateUserRequestDTO): Promise<void>;
}

export const USER_SERVICE = "USER_SERVICE";

export class UserServiceImpl implements UserService {
  private userRepo: UserRepo = injector.get(HTTP_USER_REPO);

  constructor() {
    makeObservable(this);
  }

  @observable users: User[] = [];
  @observable selectedUser: User | null = null;

  @action
  async getAllUsers() {
    this.users = await this.userRepo.get();
  }

  @action
  async register(values: CreateUserRequestDTO) {
    await this.userRepo.register(values);
    await this.getAllUsers();
  }

  @action
  cleanService() {
    this.users = [];
  }

  @action
  async delete(id: string) {
    await this.userRepo.delete(id);
    await this.getAllUsers();
  }

  @action
  selectUser(user: User) {
    this.selectedUser = user;
  }

  @action
  async update(id: string, values: UpdateUserRequestDTO) {
    await this.userRepo.update(id, values);
  }
}

export const useUserService = (): UserService => {
  return injector.get<UserService>(USER_SERVICE);
};
