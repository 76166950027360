import React from "react";
import { observer } from "mobx-react";
import { useBoolToggle } from "../../../hooks/useModal";
import { UsersTable } from "../users-table/users-table.component";
import { CreateUserModal } from "../create-user-modal/create-user-modal.component";
import { UpdateUserModal } from "../update-user-modal/update-user-modal.component";

export const UsersList = observer(() => {
  const [isUpdateUserOpened, openUpdateUserModal, closeUpdateUserModal] =
    useBoolToggle();

  return (
    <div>
      <div id="create-user-button" />
      <UsersTable openUpdateUserModal={openUpdateUserModal} />
      <CreateUserModal />
      <UpdateUserModal
        isUpdateUserOpened={isUpdateUserOpened}
        closeUpdateUserModal={closeUpdateUserModal}
      />
    </div>
  );
});
