import { InlineIcon } from "@iconify/react";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import { Button } from "../button/button.styles";
import { Typography } from "../typography/typography.component";

type SidebarLinkProps = {
  icon: object;
  path: string;
};

export const SidebarLink: React.FC<SidebarLinkProps> = ({
  icon,
  children,
  path,
}) => {
  const location = useLocation();

  const buttonVariant = location.pathname === path ? "primary" : "transparent";

  return (
    <Link to={path} style={{ textDecoration: "none" }}>
      <Button fullWidth variant={buttonVariant}>
        <InlineIcon icon={icon} width="28" />
        <Typography
          variant="h3"
          resetMargin
          fontColor="inherit"
          fontWeight={400}
        >
          {children}
        </Typography>
      </Button>
    </Link>
  );
};
