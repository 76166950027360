import { makeAutoObservable } from "mobx";
import { injector } from "../Injector";
import { HTTP_INVOICE_REPO, InvoiceRepo } from "./invoice.repo";
import { Invoice } from "./invoice.types";

export interface InvoiceService {
  invoice: Invoice | null;
  invoiceId: string;

  updateInvoiceId(id: string): Promise<void>;
  getInvoiceId(): Promise<void>;
  getInvoice(): Promise<void>;
}

export const INVOICE_SERVICE = "INVOICE_SERVICE";

export class InvoiceServiceImpl implements InvoiceService {
  constructor(
    private readonly invoiceRepo = injector.get<InvoiceRepo>(HTTP_INVOICE_REPO)
  ) {
    makeAutoObservable(this);
  }

  invoice: Invoice | null = null;
  invoiceId: string = "";

  async updateInvoiceId(id: string): Promise<void> {
    await this.invoiceRepo.updateId(id);

    this.invoiceId = id;
  }

  async getInvoiceId(): Promise<void> {
    const id = await this.invoiceRepo.getConfig();

    this.invoiceId = id;
  }

  async getInvoice(): Promise<void> {
    this.invoice = await this.invoiceRepo.getInvoice();
  }
}

export const useInvoiceService = () =>
  injector.get<InvoiceService>(INVOICE_SERVICE);
