import React from "react";
import { useTheme } from "styled-components";
import { Typography } from "../../../ui-kit/typography/typography.component";
import { Server } from "../../server.types";

type InformerStarsProps = {
  server: Server;
};

export const InformerStats: React.FC<InformerStarsProps> = ({ server }) => {
  const theme = useTheme();

  return (
    <>
      <Typography variant={"h7"} fontColor={theme.colors.textGrey} as={"div"}>
        CPU load: {server.stats?.cpuLoad.toFixed(2)}%
      </Typography>
      <br />
      <Typography variant={"h7"} fontColor={theme.colors.textGrey} as={"div"}>
        RAM usage: {(server.stats?.memory.used / 1024 / 1024 / 1024).toFixed(2)}{" "}
        GB / {(server.stats?.memory.total / 1024 / 1024 / 1024).toFixed(2)} GB
      </Typography>
      <br />
      <Typography variant={"h7"} fontColor={theme.colors.textGrey} as={"div"}>
        HDD usage: {(server.stats?.hdd.used / 1024 / 1024 / 1024).toFixed(2)} GB
        / {(server.stats?.hdd.total / 1024 / 1024 / 1024).toFixed(2)} GB
      </Typography>
    </>
  );
};
