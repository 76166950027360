import { yupResolver } from "@hookform/resolvers/yup";
import { AxiosError } from "axios";
import { observer } from "mobx-react";
import React from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import * as yup from "yup";
import { Button } from "../../../ui-kit/button/button.styles";
import { Input } from "../../../ui-kit/input/input.component";
import { Modal } from "../../../ui-kit/modal/modal.component";
import { useBotService } from "../../bot.service";

type CloseBotModalProps = {
  isOpened: boolean;
  closeModal: VoidFunction;
};

type CloseFormValues = {
  closingMessage: string;
};

const closingMessageSchemaValidation = yup.object().shape({
  closingMessage: yup.string().required(),
});

export const CloseBotModal: React.FC<CloseBotModalProps> = observer(
  ({ isOpened, closeModal }) => {
    const store = useBotService();

    const {
      register: closingMessageFormRegister,
      handleSubmit: closingMesssageHandleSubmit,
      formState: { errors: closingMessageErrors },
      reset,
    } = useForm<CloseFormValues>({
      resolver: yupResolver(closingMessageSchemaValidation),
    });

    const onClosingMessageSubmit = async (values: CloseFormValues) => {
      // eslint-disable-next-line no-restricted-globals
      const validateSubmit = confirm(
        `Are you sure that you want to close ${store.selectedBot?.botName}?`
      );

      if (!validateSubmit) return;

      try {
        await store.closeBot(values.closingMessage);
        closeModal();
        toast.success(`${store.selectedBot?.botName} bot closed!`);
        store.deselectBot();
        reset();
      } catch (e) {
        const error = e as AxiosError;
        toast.error(error.response?.data?.message || error.message);
      }
    };

    const closingMessageModalFooter = (
      <Button
        size="sm"
        onClick={closingMesssageHandleSubmit(onClosingMessageSubmit)}
      >
        Close bot
      </Button>
    );

    return (
      <Modal
        isOpened={isOpened}
        closeModal={closeModal}
        title="Close bot"
        footer={closingMessageModalFooter}
      >
        <Input
          label="Closing message"
          as="textarea"
          register={closingMessageFormRegister}
          name="closingMessage"
          defaultValue={store.selectedBot?.closingMessage}
          error={closingMessageErrors["closingMessage"]?.message}
        />
      </Modal>
    );
  }
);
