import { useAuthService } from "../../../auth/auth.service";
import { UserPermissions } from "../../../user/user.types";
import { Typography } from "../../../ui-kit/typography/typography.component";
import { ServerInformers } from "../../../servers/components/server-informers/server-informers.component";
import { observer } from "mobx-react";
import { useBoolToggle } from "../../../hooks/useModal";
import { useState } from "react";
import { Server } from "../../../servers/server.types";
import { DeployBotModal } from "../deploy-bot-modal/deploy-bot-modal.component";
import { useServerService } from "../../../servers/server.service";
import { LatestBotsWidget } from "../../../bot/components/latest-bots-widget/latest-bots-widget.component";
import { Button } from "../../../ui-kit/button/button.styles";

export const DashboardPublic = observer(() => {
  const authService = useAuthService();
  const serverService = useServerService();

  const [serverHost, setServerHost] = useState<Server | null>(null);
  const [isOpened, openModal, closeModal] = useBoolToggle();

  const openDeployModalForm = () => {
    setServerHost(serverService.notBusyServer);
    openModal();
  };

  return (
    <div style={{ display: "flex", gap: "48px", flexWrap: "wrap" }}>
      {authService.permissions.includes(UserPermissions.VIEW_SERVERS) && (
        <div>
          <div style={{ display: "flex", gap: "12px", alignItems: "center" }}>
            <Typography variant={"h1"}>
              Server informers ({serverService.totalPlaces} opened bots)
            </Typography>
            {authService.permissions.includes(UserPermissions.DEPLOY_BOT) && (
              <Button size="sm" onClick={openDeployModalForm}>
                Deploy
              </Button>
            )}
          </div>
          <ServerInformers />
          <DeployBotModal
            isOpened={isOpened}
            closeModal={closeModal}
            serverHost={serverHost}
          />
        </div>
      )}
      {authService.permissions.includes(UserPermissions.DEPLOY_BOT) && (
        <div>
          <Typography variant={"h1"}>Your latest bots</Typography>
          <LatestBotsWidget />
        </div>
      )}
    </div>
  );
});
