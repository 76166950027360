import React from "react";
import ReactDOM from "react-dom";
import "normalize.css/normalize.css";
import "react-toastify/dist/ReactToastify.css";
import "./injectAll";
import './config/mobx';
import { App } from "./App";
import { MyThemeProvider } from "./ui-kit/theme-context/theme.context";

ReactDOM.render(
  <React.StrictMode>
    <MyThemeProvider>
      <App />
    </MyThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
