import { AxiosError } from "axios";
import { observer } from "mobx-react-lite";
import React, { useEffect, useMemo } from "react";
import { CellProps } from "react-table";
import { toast } from "react-toastify";
import { Table } from "../../../ui-kit/table/table.component";
import { Typography } from "../../../ui-kit/typography/typography.component";
import { useInvoiceService } from "../../invoice.service";
import { InvoiceItem } from "../../invoice.types";
import { UpdateInvoiceId } from "../update-invoice-id/update-invoice-id.component";
import { CopyToClipboard } from "react-copy-to-clipboard";

export const InvoicesList = observer(() => {
  const invoiceService = useInvoiceService();

  useEffect(() => {
    (async () => {
      try {
        await invoiceService.getInvoice();
      } catch (e) {
        const error = e as AxiosError;

        toast.error(
          Array.isArray(error.response?.data?.message)
            ? error.response?.data?.message[0]
            : error.response?.data?.message || error.message
        );
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoiceService.invoiceId]);

  const columns = useMemo(() => {
    return [
      {
        Header: "Bot name",
        accessor: "description" as const,
      },
      {
        Header: "Quantity",
        accessor: "quantity" as const,
      },
      {
        Header: "Rate",
        accessor: "rate" as const,
      },
      {
        Header: "Total",
        Cell: (cell: CellProps<InvoiceItem>) => {
          const invoiceItem = cell.row.original;

          return `$${invoiceItem.quantity * invoiceItem.rate}`;
        },
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onCopy = () => {
    toast.info("Wallet copied to clipboard!");
  };

  return (
    <div>
      <UpdateInvoiceId />
      {invoiceService.invoice && (
        <div>
          <Typography variant="h1">
            {invoiceService.invoice.title}. Total: $
            {invoiceService.invoice.total}{" "}
            {invoiceService.invoice.paid > 0 &&
              `(Left: $${
                invoiceService.invoice.total - invoiceService.invoice.paid
              })`}
          </Typography>
          <Typography variant="h1">
            TRC20 wallet:{" "}
            <CopyToClipboard
              text="TW29PTWVRyZvTtGkr8LkcGynYLk4Ha1WaR"
              onCopy={onCopy}
            >
              <span style={{ cursor: "pointer" }}>
                TW29PTWVRyZvTtGkr8LkcGynYLk4Ha1WaR
              </span>
            </CopyToClipboard>
          </Typography>
          <Table
            data={invoiceService.invoice.items}
            columns={columns}
            tableKey="invoices"
          />
        </div>
      )}
    </div>
  );
});
