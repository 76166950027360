import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { name } from "faker";
import { toast } from "react-toastify";
import { AxiosError } from "axios";
import { FC } from "react";
import { observer } from "mobx-react-lite";
import { Modal } from "../../../ui-kit/modal/modal.component";
import { Button } from "../../../ui-kit/button/button.styles";
import { useTelegramAccountService } from "../../telegram-account.service";
import { FormWrapper } from "../../../ui-kit/styles";
import { Input } from "../../../ui-kit/input/input.component";

type AddTelegramAccountFormValues = {
  name: string;
  phoneNumber: string;
};

const addTelegramAccountSchemaValidation = yup.object().shape({
  name: yup.string().required(),
  phoneNumber: yup.string().required(),
});

type CreateTelegramAccountModalProps = {
  closeModal: () => void;
  isOpened: boolean;
};

export const CreateTelegramAccountModal: FC<CreateTelegramAccountModalProps> =
  observer(({ closeModal, isOpened }) => {
    const telegramAccountService = useTelegramAccountService();

    const {
      register,
      handleSubmit,
      formState: { errors, isSubmitting },
      reset,
    } = useForm<AddTelegramAccountFormValues>({
      resolver: yupResolver(addTelegramAccountSchemaValidation),
      defaultValues: {
        name: `${name.firstName()} ${name.lastName()}`,
      },
    });

    const onModalClose = () => {
      closeModal();
      reset({
        name: `${name.firstName()} ${name.lastName()}`,
        phoneNumber: "",
      });
    };

    const onSubmit = async (values: AddTelegramAccountFormValues) => {
      try {
        await telegramAccountService.createAccount(values);
        toast.success(`Account ${values.name} has been added!`);
        onModalClose();
      } catch (e) {
        const error = e as AxiosError;
        toast.error(error.response?.data?.message || error.message);
      }
    };

    const modalFooter = (
      <Button
        size="sm"
        onClick={handleSubmit(onSubmit)}
        disabled={isSubmitting}
      >
        Add telegram account
      </Button>
    );

    return (
      <Modal
        isOpened={isOpened}
        closeModal={onModalClose}
        title={"Add telegram account"}
        footer={modalFooter}
      >
        <FormWrapper>
          <Input
            label="Name"
            register={register}
            name="name"
            error={errors["name"]?.message}
          />
          <Input
            label="Phone Number"
            register={register}
            name="phoneNumber"
            error={errors["phoneNumber"]?.message}
          />
        </FormWrapper>
      </Modal>
    );
  });
