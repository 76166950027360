import styled from "styled-components";

export const ServerInformerStyled = styled.div`
  border: 2px solid ${(p) => p.theme.colors.widgetBorderColor};
  border-radius: 24px;
  width: 225px;
  height: 300px;
  display: flex;
  justify-content: space-between;
  padding: 20px;
  flex-direction: column;

  .places {
    margin-bottom: 8px;
  }
`;

export const ServerInformerIconWrapper = styled.div`
  background-color: ${(p) => p.theme.colors.widgetIconInformerBackground};
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
`;

export const ServerInformersWrapperStyled = styled.div`
  display: flex;
  gap: 28px;
  flex-wrap: wrap;
`;

export const ServerInformersIconsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;
