import { Server } from "./server.types";
import { api } from "../config/api";
import { ServersListResponseDto } from "./dto/servers-list-response.dto";
import { CreateServerRequestDTO } from "./dto/create-server-request.dto";
import { UpdateServerRequestDTO } from "./dto/update-server-request.dto";

export interface ServerRepo {
  get(): Promise<Server[]>;
  create(dto: CreateServerRequestDTO): Promise<void>;
  update(dto: UpdateServerRequestDTO, id: string): Promise<void>;
}

export const HTTP_SERVER_REPO = "HTTP_SERVER_REPO";

export class HttpServerRepoImpl implements ServerRepo {
  async get() {
    const { data } = await api.get<ServersListResponseDto>("/api/servers");

    return data.map((server) => ({
      id: server._id,
      ip: server.ip,
      places: server.places,
      mongoHost: server.mongoHost,
      stats: server.usage,
      closedBots: server.closedBots,
    }));
  }

  async create(dto: CreateServerRequestDTO): Promise<void> {
    await api.post("/api/servers", dto);
  }

  async update(dto: UpdateServerRequestDTO, id: string): Promise<void> {
    await api.patch(`/api/servers/${id}`, dto);
  }
}
