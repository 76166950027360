import { opacify } from "polished";
import styled from "styled-components";

export const Sidebar = styled.div`
  width: 258px;
  height: 100%;
  background: ${(p) => opacify(-0.95, p.theme.colors.primaryPurple)};
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 24px;
  position: fixed;

  .sidebar-logo {
    margin-bottom: 88px;
  }
`;

export const SidebarMenuWrapper = styled.div`
  width: 100%;

  button {
    margin-bottom: 24px;
  }
`;

export const DashboardWrapper = styled.div`
  height: 100%;
  display: flex;
  background-color: ${(p) => p.theme.colors.primary};
`;

export const DashboardContentWrapper = styled.div`
  padding: 52px 34px;
  flex: 1;
  margin-left: 258px;
`;
