import { CSSProperties } from "react";
import styled from "styled-components";

type TypographyStyledProps = {
  fontSize: number;
  fontWeight: CSSProperties["fontWeight"];
  display?: string;
  resetMargin?: boolean;
  fontColor?: CSSProperties["color"];
};

export const TypographyStyled = styled.span<TypographyStyledProps>`
  color: ${(p) => p.fontColor || p.theme.colors.textBlack};

  font-family: ${(p) => p.theme.fonts.primary};
  font-size: ${(p) => p.fontSize}px;
  font-weight: ${(p) => p.fontWeight};

  ${(p) =>
    p.display &&
    `display: ${p.display === "inline" ? "inline-block" : "block"};`}
  ${(p) => p.resetMargin && "margin: 0;"}
`;
