import { opacify } from "polished";
import styled from "styled-components";

type TableRowProps = {
  isHeader?: boolean;
  isDragLayer?: boolean;
};

const TABLE_ROW_BORDER_RADIUS = 14;

export const TableRow = styled.tr<TableRowProps>`
  height: 74px;
  transition: all 0.3s ease;

  th:first-child {
    border-top-left-radius: ${TABLE_ROW_BORDER_RADIUS}px;
  }
  th:last-child {
    border-top-right-radius: ${TABLE_ROW_BORDER_RADIUS}px;
  }
  th:first-child {
    border-bottom-left-radius: ${TABLE_ROW_BORDER_RADIUS}px;
  }
  th:last-child {
    border-bottom-right-radius: ${TABLE_ROW_BORDER_RADIUS}px;
  }

  td:first-child {
    border-top-left-radius: ${TABLE_ROW_BORDER_RADIUS}px;
  }
  td:last-child {
    border-top-right-radius: ${TABLE_ROW_BORDER_RADIUS}px;
  }
  td:first-child {
    border-bottom-left-radius: ${TABLE_ROW_BORDER_RADIUS}px;
  }
  td:last-child {
    border-bottom-right-radius: ${TABLE_ROW_BORDER_RADIUS}px;
  }

  ${(p) =>
    p.isHeader
      ? `
    background-color: ${opacify(-0.95, p.theme.colors.primaryPurple)};
    `
      : `
    &:hover {
    background-color: ${opacify(-0.9, p.theme.colors.primaryPurple)};
  }`}

  ${(p) =>
    p.isDragLayer && `background-color: ${p.theme.colors.primaryPurple};`}
`;

export const TableStyled = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
`;

type TableCellProps = {
  isDrag?: boolean;
  isDragging?: boolean;
};

export const TableCell = styled.td<TableCellProps>`
  padding: 0 24px;

  span {
    ${(p) =>
      p.isDrag ? (p.isDragging ? "cursor: grabbing;" : "cursor: grab;") : ""};
  }
`;

export const TableHeadCell = styled.th`
  text-align: left;
  padding: 0 24px;
`;

export const TableHead = styled.thead``;
