import React from "react";
import { LogoStyled } from "./logo.styles";
import { ReactComponent as LogoIcon } from "../../assets/images/logo.svg";
import { Typography } from "../typography/typography.component";

type LogoProps = {
  wrapperClassName?: string;
}

export const Logo: React.FC<LogoProps> = ({ wrapperClassName }) => {
  return (
    <LogoStyled className={wrapperClassName}>
      <LogoIcon width="48" />
      <Typography variant="h3" display="inline" resetMargin>
        Palm Tree
      </Typography>
    </LogoStyled>
  );
};
