import { InlineIcon } from "@iconify/react";
import React from "react";
import {
  FilterBarBody,
  FilterBarContainer,
  FilterBarContent,
  FilterBarHeader,
  FilterSidebar,
} from "./filter-bar.styles";
import filterIcon from "@iconify-icons/uil/filter";
import { Typography } from "../typography/typography.component";
import { Button } from "../button/button.styles";

type FilterBarProps = {
  isVisible: boolean;
  onApply: VoidFunction;
};

export const FilterBar: React.FC<FilterBarProps> = ({
  children,
  isVisible,
  onApply,
}) => {
  return (
    <FilterBarContainer isVisible={isVisible}>
      <FilterSidebar>
        <FilterBarContent>
          <div>
            <FilterBarHeader>
              <InlineIcon icon={filterIcon} height={32} />{" "}
              <Typography variant="h3" resetMargin>
                Filter
              </Typography>
            </FilterBarHeader>
            <FilterBarBody>{children}</FilterBarBody>
          </div>
          <Button fullWidth justifyContent="center" onClick={onApply}>
            Apply
          </Button>
        </FilterBarContent>
      </FilterSidebar>
    </FilterBarContainer>
  );
};
