import { Route } from "react-router-dom";
import { TelegramAccountList } from "./components/telegram-account-list/telegram-account-list.component";

export const TelegramAccountEntrypoint = () => {
  return (
    <>
      <Route path="/telegram-accounts" component={TelegramAccountList} />
    </>
  );
};
