import { useServerService } from "../../server.service";
import { useEffect } from "react";
import {
  ServerInformerIconWrapper,
  ServerInformersIconsWrapper,
  ServerInformersWrapperStyled,
} from "./server-informers.styled";
import { Typography } from "../../../ui-kit/typography/typography.component";
import { useTheme } from "styled-components";
import serverIcon from "@iconify-icons/uil/server";
import { InlineIcon } from "@iconify/react";
import { observer } from "mobx-react";
import { InformerStats } from "../informer-stats/informer-stats.component";
import { InformerStyled } from "../../../ui-kit/informer-wrapper/informer-wrapper.styles";

type ServerInformersProps = {};

export const ServerInformers: React.FC<ServerInformersProps> = observer(() => {
  const serverService = useServerService();
  const theme = useTheme();

  useEffect(() => {
    serverService.getAllServers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ServerInformersWrapperStyled>
      {serverService.servers.map((server) => {
        return (
          <InformerStyled key={`server-${server.id}`}>
            <ServerInformersIconsWrapper>
              <ServerInformerIconWrapper>
                <InlineIcon
                  icon={serverIcon}
                  height={27}
                  color={theme.colors.primaryPurple}
                />
              </ServerInformerIconWrapper>
            </ServerInformersIconsWrapper>
            <div>
              <Typography variant={"semibold"} className={"places"} as={"div"}>
                {server.places.total - server.closedBots} opened bots
              </Typography>
              <a href={`ssh://root@${server.ip}`}>{server.ip}</a>
              <br />
              <br />
              <InformerStats server={server} />
            </div>
          </InformerStyled>
        );
      })}
    </ServerInformersWrapperStyled>
  );
});
