import { Server } from "./server.types";
import { HTTP_SERVER_REPO, ServerRepo } from "./server.repo";
import { injector } from "../Injector";
import { action, computed, makeObservable, observable } from "mobx";
import { CreateServerRequestDTO } from "./dto/create-server-request.dto";
import { UpdateServerRequestDTO } from "./dto/update-server-request.dto";

export interface ServerService {
  servers: Server[];
  selectedServer: Server | null;
  totalPlaces: number;
  notBusyServer: Server;

  getAllServers(): Promise<void>;
  cleanService(): void;
  createServer(values: CreateServerRequestDTO): Promise<void>;
  selectServer(server: Server): void;
  updateServer(values: UpdateServerRequestDTO): Promise<void>;
  deselectServer(): void;
}

export const SERVER_SERVICE = "SERVER_SERVICE";

export class ServerServiceImpl implements ServerService {
  private serverRepo: ServerRepo = injector.get(HTTP_SERVER_REPO);

  constructor() {
    makeObservable(this);
  }

  @observable servers: Server[] = [];
  @observable selectedServer: Server | null = null;

  @computed
  get totalPlaces(): number {
    return this.servers.reduce((acc, val) => {
      return acc + val.places.total;
    }, 0);
  }

  @action
  async getAllServers(): Promise<void> {
    this.servers = await this.serverRepo.get();
  }

  @action
  cleanService() {
    this.servers = [];
  }

  @action
  async createServer(values: CreateServerRequestDTO): Promise<void> {
    await this.serverRepo.create(values);
    await this.getAllServers();
  }

  @action
  selectServer(server: Server): void {
    this.selectedServer = server;
  }

  @action
  async updateServer(values: UpdateServerRequestDTO): Promise<void> {
    await this.serverRepo.update(values, this.selectedServer!.id);
  }

  @action
  deselectServer(): void {
    this.selectedServer = null;
  }

  @computed
  get notBusyServer() {
    const sortedServers = this.servers
      .slice()
      .sort(
        (a, b) =>
          a.places.total - a.closedBots - (b.places.total - b.closedBots)
      );
    console.log(
      "🚀 ~ file: server.service.ts ~ line 77 ~ ServerServiceImpl ~ getnotBusyServer ~ sortedServers",
      sortedServers
    );

    return sortedServers[0];
  }
}

export const useServerService = (): ServerService => {
  return injector.get<ServerService>(SERVER_SERVICE);
};
