import { AuthRepo, HttpAuthRepoImpl, HTTP_AUTH_REPO } from "./auth/auth.repo";
import {
  AuthService,
  AuthServiceImpl,
  AUTH_SERVICE,
} from "./auth/auth.service";
import { BotRepo, HttpBotRepoImpl, HTTP_BOT_REPO } from "./bot/bot.repo";
import { BotService, BotServiceImpl, BOT_SERVICE } from "./bot/bot.service";
import { injector } from "./Injector";
import { UserRepo, HTTP_USER_REPO, HttpUserRepoImpl } from "./user/user.repo";
import {
  UserService,
  UserServiceImpl,
  USER_SERVICE,
} from "./user/user.service";
import {
  HTTP_SERVER_REPO,
  HttpServerRepoImpl,
  ServerRepo,
} from "./servers/server.repo";
import {
  SERVER_SERVICE,
  ServerService,
  ServerServiceImpl,
} from "./servers/server.service";
import {
  HttpInvoiceRepo,
  HTTP_INVOICE_REPO,
  InvoiceRepo,
} from "./invoices/invoice.repo";
import {
  InvoiceService,
  InvoiceServiceImpl,
  INVOICE_SERVICE,
} from "./invoices/invoice.service";
import {
  TelegramAccountService,
  TelegramAccountServiceImpl,
  TELEGRAM_ACCOUNT_SERVICE,
} from "./telegram-accounts/telegram-account.service";
import {
  HttpTelegramAccountRepoImpl,
  HTTP_TELEGRAM_ACCOUNT_REPO,
  TelegramAccountRepo,
} from "./telegram-accounts/telegram-account.repo";

injector.set<AuthRepo>(HTTP_AUTH_REPO, new HttpAuthRepoImpl());
injector.set<AuthService>(AUTH_SERVICE, new AuthServiceImpl());
injector.set<TelegramAccountRepo>(
  HTTP_TELEGRAM_ACCOUNT_REPO,
  new HttpTelegramAccountRepoImpl()
);
injector.set<TelegramAccountService>(
  TELEGRAM_ACCOUNT_SERVICE,
  new TelegramAccountServiceImpl()
);
injector.set<BotRepo>(HTTP_BOT_REPO, new HttpBotRepoImpl());
injector.set<BotService>(BOT_SERVICE, new BotServiceImpl());
injector.set<UserRepo>(HTTP_USER_REPO, new HttpUserRepoImpl());
injector.set<UserService>(USER_SERVICE, new UserServiceImpl());
injector.set<ServerRepo>(HTTP_SERVER_REPO, new HttpServerRepoImpl());
injector.set<ServerService>(SERVER_SERVICE, new ServerServiceImpl());
injector.set<InvoiceRepo>(HTTP_INVOICE_REPO, new HttpInvoiceRepo());
injector.set<InvoiceService>(INVOICE_SERVICE, new InvoiceServiceImpl());
