import { createPortal } from "react-dom";
import { InlineIcon } from "@iconify/react";
import timesIcon from "@iconify-icons/uil/times";
import { useTheme } from "styled-components";
import { nanoid } from "nanoid";
import { useEffect, useMemo, useState } from "react";
import { Typography } from "../typography/typography.component";
import {
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  ModalOverlay,
  ModalStyled,
  ModalTitle,
} from "./modal.styles";

type ModalProps = {
  isOpened: boolean;
  closeModal: () => void;
  title: string;
  footer: React.ReactElement;
  openCallback?: () => void;
};

export const Modal: React.FC<ModalProps> = ({
  isOpened,
  closeModal,
  title,
  children,
  footer,
  openCallback,
}) => {
  const theme = useTheme();

  const divId = useMemo(() => {
    return nanoid();
  }, []);

  const [readyToRender, setReadyToRender] = useState(false);
  useEffect(() => {
    const div = document.createElement("div");
    div.id = divId;
    div.setAttribute(
      "style",
      "position: fixed; width: 100%; height: 100%; top: 0; visibility: hidden;"
    );
    document.body.appendChild(div);
    setReadyToRender(true);

    return () => {
      document.body.removeChild(div);
    };
  }, [divId]);

  useEffect(() => {
    const element = document.getElementById(divId);

    if (!readyToRender || !isOpened) {
      if (element) {
        element.style.visibility = "hidden";
      }
    }

    if (readyToRender && isOpened) {
      if (element) {
        element.style.visibility = "visible";
      }
    }
  }, [readyToRender, isOpened, divId]);

  useEffect(() => {
    if (isOpened && openCallback) {
      openCallback();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpened]);

  if (!readyToRender || !isOpened) return null;

  return createPortal(
    <ModalOverlay>
      <ModalStyled>
        <ModalTitle>
          <Typography variant="h3" resetMargin>
            {title}
          </Typography>
          <ModalCloseButton onClick={closeModal}>
            <InlineIcon
              icon={timesIcon}
              width="28"
              color={theme.colors.textBlack}
            />
          </ModalCloseButton>
        </ModalTitle>
        <ModalBody>{children}</ModalBody>
        <ModalFooter>{footer}</ModalFooter>
      </ModalStyled>
    </ModalOverlay>,
    document.getElementById(divId)!
  );
};
