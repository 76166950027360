import { InlineIcon } from "@iconify/react";
import { observer } from "mobx-react";
import filterIcon from "@iconify-icons/uil/filter";
import { useBoolToggle } from "../../../hooks/useModal";
import { Button } from "../../../ui-kit/button/button.styles";
import { useBotService } from "../../bot.service";
import { FilterOpenClosed } from "../../bot.types";
import { BotSearchInput } from "../bot-search-input/bot-search-input.component";
import { FilterSearchBar } from "../bots-list/bots-list.styles";
import { FilterBar } from "../../../ui-kit/filter-bar/filter-bar.component";
import { Typography } from "../../../ui-kit/typography/typography.component";
import { DropdownMenu } from "../../../ui-kit/dropdown-menu/dropdown-menu.component";
import { useTelegramAccountService } from "../../../telegram-accounts/telegram-account.service";
import { useEffect } from "react";

export const BotFilter = observer(() => {
  const botService = useBotService();
  const telegramAccountService = useTelegramAccountService();

  const filterOpenOrClosedItems = [
    {
      label: FilterOpenClosed.ALL,
      onClick: () => botService.changeClosedFilter(null),
    },
    {
      label: FilterOpenClosed.CLOSED,
      onClick: () => botService.changeClosedFilter(true),
    },
    {
      label: FilterOpenClosed.OPENED,
      onClick: () => botService.changeClosedFilter(false),
    },
  ];
  const [isOpened, open, close] = useBoolToggle();
  const onFilterApply = () => {
    close();
    botService.fetchBots();
  };

  useEffect(() => {
    if (telegramAccountService.accounts.length === 0) {
      telegramAccountService.getAllAccounts();
    }
  }, [telegramAccountService.accounts]);
  const accountFilterItems = [
    {
      label: "All",
      onClick: () => botService.changeOwnerFilter(null),
    },
    ...telegramAccountService.accounts.map((account) => ({
      label: account.name,
      onClick: () => botService.changeOwnerFilter(account.id),
    })),
  ];

  return (
    <>
      <FilterSearchBar>
        <BotSearchInput />
        <Button noIconMargin onClick={open}>
          <InlineIcon width={28} icon={filterIcon} />
        </Button>
      </FilterSearchBar>
      <FilterBar isVisible={isOpened} onApply={onFilterApply}>
        <div>
          <Typography variant="semibold">Open or closed</Typography>
          <DropdownMenu
            items={filterOpenOrClosedItems}
            placeholder={botService.filterPlaceholder}
            fullWidth
          />
        </div>

        <div>
          <Typography variant="semibold">Account owner</Typography>
          <DropdownMenu
            items={accountFilterItems}
            placeholder={botService.ownerPlaceolder}
            fullWidth
            maxHeight={260}
          />
        </div>
      </FilterBar>
    </>
  );
});
