import { opacify } from "polished";
import styled from "styled-components";

export const ModalOverlay = styled.div`
  position: absolute;
  z-index: 9;
  width: 100%;
  height: 100%;
  top: 0;
  background: rgba(54, 70, 112, 0.5);
  backdrop-filter: blur(8px);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ModalStyled = styled.div`
  background-color: ${(p) => p.theme.colors.primary};
  width: 500px;
  border-radius: 14px;
  box-shadow: 0px 4px 24px
    ${(p) => opacify(-0.85, p.theme.colors.primaryPurple)};
`;

export const ModalTitle = styled.div`
  background-color: ${(p) => opacify(-0.85, p.theme.colors.primaryPurple)};
  border-top-left-radius: 14px;
  border-top-right-radius: 14px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px;
`;

export const ModalCloseButton = styled.button`
  background-color: transparent;
  border: 0;
  padding: 7px 8px;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background-color: ${(p) => opacify(-0.9, p.theme.colors.primaryPurple)};
  }
`;

export const ModalBody = styled.div`
  padding: 24px;
  max-height: calc(100vh - 200px);
  overflow: auto;
`;

export const ModalFooter = styled.div`
  padding: 12px 24px;
  border-top: 1px solid ${(p) => opacify(-0.9, p.theme.colors.textBlack)};
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;
