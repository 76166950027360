import { opacify } from "polished";
import styled from "styled-components";
import { Button } from "../button/button.styles";

export const DropdownMenuWrapper = styled.div`
  position: relative;
`;

type DropdownMenuButtonProps = {
  isActive: boolean;
  fullWidth: boolean;
};

export const DropdownMenuButton = styled.button<DropdownMenuButtonProps>`
  background-color: ${(p) =>
    p.isActive ? opacify(-0.9, p.theme.colors.primaryPurple) : "transparent"};
  border: 0;
  padding: 7px 8px;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  ${(p) => p.fullWidth && "width: 100%;"}

  &:hover {
    background-color: ${(p) => opacify(-0.9, p.theme.colors.primaryPurple)};
  }

  svg {
    color: ${(p) => p.theme.colors.moreIconColor};
  }
`;

type DropdownMenuStyledProps = {
  isVisible: boolean;
  fullWidth: boolean;
  rightSide: boolean;
};

export const DropdownMenuStyled = styled.div<DropdownMenuStyledProps>`
  padding: 16px 12px;
  border-radius: 12px;
  box-shadow: 0px 4px 24px
    ${(p) => opacify(-0.85, p.theme.colors.primaryPurple)};
  position: absolute;
  margin-top: 16px;
  z-index: 9;
  background-color: ${(p) => p.theme.colors.primary};
  transition: all 0.3s ease;
  ${(p) => p.fullWidth && "width: 100%;"}

  visibility: ${(p) => (p.isVisible ? "visible" : "hidden")};
  opacity: ${(p) => (p.isVisible ? 1 : 0)};

  ${(p) => p.rightSide && `right: 50px; white-space: nowrap;`}

  ${Button}:not(:last-of-type) {
    margin-bottom: 8px;
  }

  overflow: scroll;
`;
