import { UpdateUserFormValues, UserPermissions } from "../../user.types";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import { Button } from "../../../ui-kit/button/button.styles";
import React from "react";
import { Modal } from "../../../ui-kit/modal/modal.component";
import { useUserService } from "../../user.service";
import * as yup from "yup";
import { UpdateUserForm } from "../update-user-form/update-user-form.component";

const updateUserSchemaValidation = yup.object().shape({
  permissions: yup.array().min(1).required(),
});

type UpdateUserModalProps = {
  isUpdateUserOpened: boolean;
  closeUpdateUserModal: () => void;
};

export const UpdateUserModal: React.FC<UpdateUserModalProps> = ({
  isUpdateUserOpened,
  closeUpdateUserModal,
}) => {
  const userService = useUserService();

  const permissionsForDefaultValues = Object.values(UserPermissions).map(
    (perm) => ({
      label: perm,
      value: false,
    })
  );

  const {
    register: updateUserRegister,
    handleSubmit: updateUserHandleSubmit,
    control: updateUserControl,
    setValue: updateUserSetValue,
  } = useForm<UpdateUserFormValues>({
    resolver: yupResolver(updateUserSchemaValidation),
    defaultValues: {
      permissions: permissionsForDefaultValues,
    },
  });

  const onUpdateUserSubmit = async (values: UpdateUserFormValues) => {
    const prepareValues = {
      permissions: values.permissions.reduce<UserPermissions[]>((acc, perm) => {
        if (perm.value) {
          acc.push(perm.label);
        }

        return acc;
      }, []),
    };

    try {
      await userService.update(userService.selectedUser!.id, prepareValues);
      toast.success(`${userService.selectedUser!.email} is updated!`);
    } catch (e) {
      toast.error(e.response?.data?.message || e.message);
    }
  };
  const updateUserModalFooter = (
    <Button size="sm" onClick={updateUserHandleSubmit(onUpdateUserSubmit)}>
      Update User
    </Button>
  );
  const updateUserModalOpenCallback = () => {
    for (let i = 0; i < permissionsForDefaultValues.length; i++) {
      updateUserSetValue(
        `permissions.${i}.value`,
        userService.selectedUser?.permissions.includes(
          permissionsForDefaultValues[i].label
        ) ?? false
      );
    }
  };

  return (
    <Modal
      isOpened={isUpdateUserOpened}
      closeModal={closeUpdateUserModal}
      title={"Update user"}
      footer={updateUserModalFooter}
      openCallback={updateUserModalOpenCallback}
    >
      <UpdateUserForm
        updateUserControl={updateUserControl}
        updateUserRegister={updateUserRegister}
      />
    </Modal>
  );
};
