import { action, computed, makeObservable, observable } from "mobx";
import { debounce } from "lodash";
import { injector } from "../Injector";
import { BotRepo, HTTP_BOT_REPO } from "./bot.repo";
import {
  Bot,
  BotConfigFormValues,
  FilterOpenClosed,
  LatestBots,
} from "./bot.types";
import { BotListFilter } from "./dto/bot-list-filter.dto";
import { BOTS_PER_PAGE } from "./bot.constants";
import {
  TelegramAccountService,
  TELEGRAM_ACCOUNT_SERVICE,
} from "../telegram-accounts/telegram-account.service";

export const BOT_SERVICE = "BOT_SERVICE";

export interface BotService {
  bots: Bot[] | null;
  selectedId: string | null;
  selectedBot: Bot | null;
  totalPages: number;
  filter: BotListFilter;
  filterPlaceholder: FilterOpenClosed;
  ownerPlaceolder: string;
  latestBots: LatestBots;

  fetchBots(): Promise<void>;
  cleanBotsListData(): void;
  selectBot(id: string): void;
  deselectBot(): void;
  closeBot(closingMessage: string): Promise<void>;
  openBot(id: string): Promise<void>;
  restartBot(id: string): Promise<void>;
  search(value: string): void;
  changeConfig(config: BotConfigFormValues): Promise<void>;
  changePage(page: number): void;
  deployBot(hostIp: string, botName: string, ownerId: string): Promise<string>;
  changeClosedFilter(value: boolean | null): void;
  turnOnOff(id: string): void;
  downloadFile(id: string): Promise<Blob>;
  uploadFile(id: string, file: File): Promise<void>;
  downloadReport(): Promise<Blob>;
  changeOwnerFilter(id: string | null): void;
  fetchLatestBots(): Promise<void>;
}

export class BotServiceImpl implements BotService {
  private botRepo: BotRepo = injector.get(HTTP_BOT_REPO);
  private defaultFilterValue: BotListFilter = {
    search: "",
    limit: BOTS_PER_PAGE,
    offset: 0,
    isClosed: false,
    owner: null,
  };
  private telegramAccountService = injector.get<TelegramAccountService>(
    TELEGRAM_ACCOUNT_SERVICE
  );

  constructor() {
    makeObservable(this);
  }

  @observable bots: Bot[] | null = null;
  @observable selectedId: string | null = null;
  @observable filter: BotListFilter = {
    ...this.defaultFilterValue,
  };
  @observable totalPages: number = 0;
  @observable latestBots: LatestBots = {
    detective: "",
    ico: "",
    airdrop: "",
  };

  @computed
  get selectedBot() {
    if (!this.selectedId) return null;

    const bot = this.bots?.find((b) => b.id === this.selectedId);

    if (!bot) return null;

    return bot;
  }

  @computed
  get filterPlaceholder() {
    if (this.filter.isClosed) {
      return FilterOpenClosed.CLOSED;
    } else if (this.filter.isClosed === false) {
      return FilterOpenClosed.OPENED;
    }

    return FilterOpenClosed.ALL;
  }

  @computed
  get ownerPlaceolder() {
    if (this.filter.owner) {
      const owner = this.telegramAccountService.accounts.find(
        (a) => a.id === this.filter.owner
      );

      return owner?.name ?? "All";
    }

    return "All";
  }

  @action
  async fetchBots() {
    const [bots, total] = await this.botRepo.get(this.filter);

    this.bots = bots;
    this.totalPages = Math.ceil(total / BOTS_PER_PAGE);
  }

  @action
  cleanBotsListData() {
    this.bots = [];
    this.filter = {
      ...this.defaultFilterValue,
    };
  }

  @action
  selectBot(id: string) {
    this.selectedId = id;
  }

  @action
  deselectBot() {
    this.selectedId = null;
  }

  @action
  async closeBot(closingMessage: string) {
    const updatedBot = await this.botRepo.update(this.selectedId!, {
      isClosed: true,
      closedMessage: closingMessage,
    });

    this.replaceBot(updatedBot);
  }

  @action
  async openBot(id: string) {
    const updatedBot = await this.botRepo.update(id, {
      isClosed: false,
    });

    this.replaceBot(updatedBot);
  }

  @action
  async restartBot(id: string) {
    await this.botRepo.restartBot(id);
  }

  private debouncedFetch = debounce(() => this.fetchBots(), 300);

  @action
  search(value: string = "") {
    this.filter.search = value;

    this.debouncedFetch();
  }

  @action
  async changeConfig(config: BotConfigFormValues) {
    await this.botRepo.update(this.selectedId!, config);
  }

  @action
  private replaceBot(updatedBot: Bot) {
    this.bots =
      this.bots?.map((bot) => {
        if (bot.id === updatedBot.id) {
          return updatedBot;
        }

        return bot;
      }) ?? null;
  }

  @action
  changePage(page: number): void {
    this.bots = [];
    this.filter.offset = page * BOTS_PER_PAGE;
    this.fetchBots();
  }

  @action
  async deployBot(
    serverId: string,
    botName: string,
    ownerId: string
  ): Promise<string> {
    const closedBotName = await this.botRepo.deployBot({
      serverId,
      botName,
      ownerId,
    });

    return closedBotName;
  }

  @action
  changeClosedFilter(value: boolean | null) {
    this.filter.isClosed = value;
  }

  @action
  async turnOnOff(id: string) {
    const updatedBot = await this.botRepo.turnOnOff(id);

    this.replaceBot(updatedBot);
  }

  @action
  async downloadFile(id: string) {
    const fileBlob = await this.botRepo.downloadFile(id);

    return fileBlob;
  }

  @action
  async uploadFile(id: string, file: File): Promise<void> {
    await this.botRepo.uploadFile(id, file);
  }

  @action
  async downloadReport(): Promise<Blob> {
    const fileBlob = await this.botRepo.downloadReport();

    return fileBlob;
  }

  @action
  changeOwnerFilter(id: string | null): void {
    this.filter.owner = id;
  }

  @action async fetchLatestBots(): Promise<void> {
    const data = await this.botRepo.latestBots();

    this.latestBots = data;
  }
}

export const useBotService = (): BotService => {
  return injector.get<BotService>(BOT_SERVICE);
};
