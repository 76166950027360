import React from "react";
import { CellProps } from "react-table";
import { Bot } from "../../bot.types";

type TableCreatorColumnProps = CellProps<Bot>;

export const TableCreatorColumn: React.FC<TableCreatorColumnProps> = (cell) => {
  const {
    row: { original: bot },
  } = cell;

  return <>{bot.creator === "reg@batarin.zp.ua" ? "Dmytro" : "Nick"}</>;
};
