import React, { useMemo } from "react";
import { CellProps } from "react-table";
import { Bot } from "../../bot.types";
import airdropIcon from "../../../assets/images/airdrop.jpeg";
import detectiveIcon from "../../../assets/images/detective.jpeg";
import icoIcon from "../../../assets/images/ico.jpeg";
import { AdvertiserIcon } from "./table-bot-icon-column.styles";

type TableBotIconColumnProps = CellProps<Bot>;

export const TableIconNameColumn: React.FC<TableBotIconColumnProps> = (
  cell
) => {
  const {
    row: { original: bot },
  } = cell;

  const iconSrc = useMemo(() => {
    if (bot.advertiser.toLowerCase() === "airdropdetective") {
      return detectiveIcon;
    } else if (
      ["airdrop", "airdropstar"].includes(bot.advertiser.toLowerCase())
    ) {
      return airdropIcon;
    }

    return icoIcon;
  }, [bot.advertiser]);

  return (
    <AdvertiserIcon src={iconSrc} width="28" height="28" alt={bot.advertiser} />
  );
};
