import React, { CSSProperties } from "react";
import { TypographyStyled } from "./typography.styles";

type TypographyProps = {
  variant?: "span" | "h1" | "h3" | "h4" | "semibold" | "h7";
  display?: "inline" | "block";
  resetMargin?: boolean;
  fontSize?: number;
  as?: React.ComponentProps<typeof TypographyStyled>["as"];
  className?: string;
  fontColor?: CSSProperties["color"];
  fontWeight?: CSSProperties["fontWeight"];
};

export const Typography: React.FC<TypographyProps> = ({
  variant,
  children,
  fontSize: overrideFontSize,
  as: overrideAs,
  fontWeight: overrideFontWeight,
  ...props
}) => {
  const fontSize = {
    span: 20,
    h1: 28,
    h3: 20,
    h4: 18,
    semibold: 20,
    h7: 14,
  };

  const fontWeight = {
    span: 400,
    h1: 600,
    h3: 500,
    h4: 500,
    semibold: 600,
    h7: 400,
  };

  const asVariants: Record<string, string> = {
    h7: 'span',
    semibold: 'span',
  }

  let asProp = overrideAs || variant;
  if(variant && asVariants[variant] && !overrideAs) {
    asProp = asVariants[variant];
  }

  return (
    <TypographyStyled
      as={asProp}
      fontSize={overrideFontSize || fontSize[variant!]}
      fontWeight={overrideFontWeight || fontWeight[variant!]}
      {...props}
    >
      {children}
    </TypographyStyled>
  );
};

Typography.defaultProps = {
  variant: "span",
};
